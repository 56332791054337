<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { rails_url } from '@/modules/shared/utils/rails'
import { VButton, VButtonInvisible, VLoading, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import PaymentMethodCard from '../components/payment-method-card.vue'
import SubscriptionCard from '../components/subscription-card.vue'
import VStat from '../components/v-stat.vue'
import ViewInStripeButton from '../components/view-in-stripe-button.vue'
import { useCustomerStore } from '../stores/customer-store'
import { useProductStore } from '../stores/product-store'
import {
  invoice_amount_remaining,
  invoice_subtotal,
  is_invoice_open,
  is_sub_invoice,
  is_sub_trialing,
  subscription_upcoming_invoice_subtotal,
  subscriptions_has_any_discount,
} from '../utils/stripe'
import { to_currency } from '../utils'
import { useRoute } from 'vue-router'

const authStore = useAuthStore()
const customerStore = useCustomerStore()
const productStore = useProductStore()
const route = useRoute()

///////////////////////////////////////////////////////////////////////////////
// Keyboard shortcuts
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const ready = ref(false)
const default_payment_method_id = computed(() => customerStore.customer.invoice_settings.default_payment_method)
const billable = { type: 'group' }

const pay_now_classes = ref(undefined)
const is_payment_success = ref(false)
const is_payment_loading = ref(false)

const pay = async () => {
  // let subscriptions = customerStore.stripe_subscriptions_for_group

  // TODO: We need to filter by other statuses
  // let subscriptionToPay = subscriptions.filter((elem) => elem.status == 'incomplete_expired')

  // const response = await customerStore.pay_subscription(subscriptionToPay[0].id)

  is_payment_loading.value = true
  const response = await customerStore.pay_subscription(customerStore.stripe_subscriptions_for_group[0].id)

  if (response !== undefined && response.response.status !== 201) {
    pay_now_classes.value = 'bg-red-600'
    is_payment_success.value = false
  } else {
    pay_now_classes.value = 'bg-sky-600'
    is_payment_success.value = true
  }
  is_payment_loading.value = false
}

const has_unpaid_subscriptions = computed(() => {
  if (customerStore.stripe_subscriptions_for_group.some((subscription) => is_sub_trialing(subscription))) return true

  const invoices = customerStore.customer.stripe_invoices.filter(is_invoice_open).filter((invoice) => {
    return customerStore.stripe_subscriptions_for_group.some((sub) => is_sub_invoice(sub, invoice))
  })

  return invoices.length !== 0
})

const formatted_invoice_amount_remaining = computed(() => {
  if (is_payment_success.value) return '$0'

  const stripe_subscriptions = customerStore.stripe_subscriptions_for_group
  const total_amount_of_trialing_subscription = stripe_subscriptions
    .filter(is_sub_trialing)
    .reduce((total, subscription) => total + subscription.stripe_upcoming_invoice_cache.amount_remaining, 0)

  const invoices = customerStore.customer.stripe_invoices.filter(is_invoice_open).filter((invoice) => {
    return customerStore.stripe_subscriptions_for_group.some((sub) => is_sub_invoice(sub, invoice))
  })

  const amount = invoice_amount_remaining(invoices) + total_amount_of_trialing_subscription
  const currency = invoices[0]?.stripe_cache?.currency || 'usd'

  return to_currency(amount, currency)
})

const formatted_invoice_subtotal_amount = computed(() => {
  if (is_payment_success.value) return null

  // prettier-ignore
  const stripe_subscriptions = customerStore.stripe_subscriptions_for_group

  if (!subscriptions_has_any_discount(stripe_subscriptions)) return null

  const trialing_subscriptions = stripe_subscriptions.filter(is_sub_trialing)

  const invoices = customerStore.customer.stripe_invoices.filter(is_invoice_open).filter((invoice) => {
    return customerStore.stripe_subscriptions_for_group.some((sub) => is_sub_invoice(sub, invoice))
  })

  const amount = invoice_subtotal(invoices) + subscription_upcoming_invoice_subtotal(trialing_subscriptions)
  const currency = invoices[0]?.stripe_cache?.currency || 'usd'

  return to_currency(amount, currency)
})

const onSetDefaultPaymentMethod = async (payment_method_id) => {
  await customerStore.set_payment_method_as_default(payment_method_id, billable)
}

const has_payment_method = computed((): boolean => customerStore.customer.stripe_payment_methods.length !== 0)

const has_default_payment_method = computed((): boolean => default_payment_method_id.value !== null)

const can_pay = computed((): boolean => {
  return has_payment_method.value && has_default_payment_method.value
})

const items = ref([])

onMounted(async () => {
  // removes page flicker when returning to this page
  if (customerStore.customer !== null) {
    ready.value = true
  }

  // We need to wait when a new payment is added because
  // the service set as a default is still processing changes under the hood
  if (route.query.redirect_status !== undefined) {
    await new Promise((resolve) => setTimeout(resolve, 10000))
  }
  // implement promise.all to allow for parallel requests
  // prettier-ignore
  await Promise.all([
    customerStore.retrieve(),
    productStore.list(),
  ])

  // Build list representing the current plan for the billable
  // NOTE: this was copied from the entity page
  // NOTE: these steps are both more efficient and less efficient, but it's easy to read and that's most important
  // 1. select subscriptions for the "billable"
  // 2. expand subscription items into separate "subscriptions" (enables sorting by name across subscriptions)
  // 3. create map between product name and subscription id
  // 4. combine subscription schedules and subscriptions
  // 5. sort combined array by product name

  // #1, #2, #3
  // prettier-ignore
  const stripe_subscriptions = customerStore.stripe_subscriptions_for_group
    .map((subscription) => {
      return subscription.items.map((item) => {
        return {
          id: subscription.id,
          product_id: item.price.product,
          subscription: {
            ...subscription,
            items: [item]
          }
        }
      })
    })
    .flat()

  // needed for sorting
  const products = productStore.products

  // #4, #5
  items.value = [...stripe_subscriptions].sort((a, b) => {
    const product_a = products[a.product_id].name.toUpperCase()
    const product_b = products[b.product_id].name.toUpperCase()
    if (product_a < product_b) {
      return -1
    }
    if (product_a > product_b) {
      return 1
    }
    return 0
  })

  ready.value = true
})
</script>

<template>
  <TheLayout>
    <div class="h-full py-12 sm:mx-12" v-if="!ready">
      <div class="flex h-full items-center justify-center pb-32">
        <VLoading class="h-6 w-6" />
      </div>
    </div>
    <div v-if="ready">
      <!-- nav -->
      <VSection>
        <div class="flex items-center justify-between">
          <div>
            <a :href="`${rails_url()}/group/settings`">
              <VButtonInvisible>← Back</VButtonInvisible>
            </a>
          </div>
          <div v-if="authStore.is_site_admin">
            <ViewInStripeButton
              :livemode="customerStore.customer.livemode"
              :resource="{ customer: customerStore.customer.stripe_customer_id }"
            />
          </div>
        </div>
      </VSection>
      <!-- stats -->
      <template v-if="authStore.is_site_admin">
        <VSection v-if="customerStore.customer.analytics.length > 0">
          <VStat :stats="customerStore.customer.analytics"></VStat>
        </VSection>
      </template>
      <!-- payment method message -->
      <VSection class="pay-now-container relative" v-show="!can_pay">
        <div class="rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] p-4">
          <p class="text-sm font-medium text-gray-700">
            <template v-if="!has_payment_method">Please add a payment method below</template>
            <template v-else-if="!has_default_payment_method">Please select a default payment method below</template>
          </p>
        </div>
      </VSection>
      <!-- checkout -->
      <VSection class="relative" v-if="has_unpaid_subscriptions">
        <VStat
          :color="pay_now_classes"
          :stats="[
            {
              name: 'Due Today',
              value: formatted_invoice_amount_remaining,
              previous_value: formatted_invoice_subtotal_amount,
            },
          ]"
        ></VStat>
        <div class="absolute bottom-0 right-0 top-0 flex flex-col items-end justify-center pr-8">
          <VButton :disabled="!can_pay" size="xl" :click="pay" :loading="is_payment_loading" class="px-16"
            >Pay now</VButton
          >
        </div>
      </VSection>
      <!-- <VSection class="relative">
        <div class="absolute top-0 right-0 bottom-0 flex items-center justify-center pr-8">
          <VButton
            :disabled="
              !customerStore.has_incomplete_stripe_subscriptions_for_group ||
              customerStore.customer.stripe_payment_methods.length === 0
            "
            size="xl"
            @click="pay"
            class="px-16"
            >Pay now</VButton
          >
        </div>
      </VSection> -->
      <!-- current plan -->
      <VSection label="Current Plan">
        <div class="relative items-start justify-between lg:flex">
          <div class="space-y-6">
            <SubscriptionCard v-for="item in items" :actions="false" :subscription="item.subscription" />
          </div>
          <div v-if="false" class="flex items-center space-x-2">
            <RouterLink
              v-if="!customerStore.has_active_stripe_subscriptions_for_group"
              :to="{ name: 'billing-group-select-plan' }"
            >
              <VButton>Select plan</VButton>
            </RouterLink>
          </div>
        </div>
      </VSection>
      <!-- payment methods -->
      <VSection label="Payment Method">
        <div class="relative items-start justify-between lg:flex">
          <div>
            <PaymentMethodCard
              v-for="payment_method in customerStore.customer.stripe_payment_methods"
              :billable="billable"
              :default_payment_method_id="default_payment_method_id"
              :payment_method="payment_method"
              :setDefaultPaymentMethod="onSetDefaultPaymentMethod"
            />
          </div>
          <div>
            <RouterLink :to="{ name: 'billing-group-add-payment-method' }">
              <VButton>Add payment method</VButton>
            </RouterLink>
          </div>
        </div>
      </VSection>
    </div>
  </TheLayout>
</template>
