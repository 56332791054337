<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { get, capitalize } from 'lodash'
import { minValue, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useExtendedI18n } from '@/i18n'
import { useModal } from '@/modules/shared/composables/use-modal'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import { initialMoney, toNumber } from '@/modules/shared/utils/money'
import { VButton, VIcon, VSection, VTable, VModal, VTextField } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import OwnershipTransferSummary from '../components/ownership-transfer-summary.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import VSelect from '../components/v-select.vue'
import { useCommitmentStore } from '../stores/commitment-store'
import { useEntityStore } from '../stores/entity-store'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { ownership_transfer_form_initial_state, useOwnershipTransferStore } from '../stores/ownership-transfer-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import { getOwnershipTransferSummary } from '../utils/ownership-transfer'
import { investorPath } from '../utils/investor'

const route = useRoute()
const { n, t } = useExtendedI18n()
const skeleton = ref(true)
const entityStore = useEntityStore()
const ownershipTransferStore = useOwnershipTransferStore()
const investorStore = useInvestorStore()
const commitmentStore = useCommitmentStore()
const vehicleStore = useInvestingVehicleStore()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')

const currentEntity = ref(null)
const currentTransfers = computed(() =>
  ownershipTransferStore.ownership_transfers.filter(
    (transfer) =>
      investorStore.state.selectedKeys.includes(transfer.seller_commitment.investor._cid) ||
      investorStore.state.selectedKeys.includes(transfer.buyer_commitment.investor._cid),
  ),
)

const sellerTranferOptions = computed(() => {
  const disabledInvestors = [transferOwnership.value.buyer_investor_cid]
  const items = commitmentStore.commitments.reduce((list, commitment) => {
    list[commitment.investor._cid] = commitment.investor
    return list
  }, {})

  return markDisabledOptions(createOptions(items, { label: 'name' }), disabledInvestors)
})

const buyerTranferOptions = computed(() => {
  const disabledInvestors = [transferOwnership.value.seller_investor_cid]

  return markDisabledOptions(createOptions(investorStore.state.items, { label: 'name' }), disabledInvestors)
})

///////////////////////////////////////////////////////////////////////////////
// ADD TRANSFER
///////////////////////////////////////////////////////////////////////////////
const transferFormRules = {
  buyer_investor_cid: { required },
  date: { required },
  price_per_unit: { required, minValueValue: minValue(0) },
  seller_investor_cid: { required },
  units_sold: { required, minValueValue: minValue(1) },
}

const transferOwnership = ref({ ...ownership_transfer_form_initial_state })
const vto$ = useVuelidate(transferFormRules, transferOwnership, { $lazy: true })
const addTransferModal = useModal()
const ownershipTransferAggregates = ref({ amount_paid: 0, net: 0, sellers_purchased_units: 0 })

const openAddTransferModal = () => {
  vto$.value.$reset()
  transferOwnership.value = { ...ownership_transfer_form_initial_state }
  addTransferModal.open()
}

async function transferOwnershipSubmit() {
  const valid = await vto$.value.$validate()
  if (!valid) return
  await ownershipTransferStore.transferOwnership(
    {
      buyer_investor_cid: transferOwnership.value.buyer_investor_cid,
      carried_interest_amount: transferOwnership.value.carried_interest_amount || 0,
      date: transferOwnership.value.date,
      entity_id: route.params.entity_id,
      other_fee: transferOwnership.value.other_fee || 0,
      price_per_unit: transferOwnership.value.price_per_unit,
      seller_investor_cid: transferOwnership.value.seller_investor_cid,
      units_sold: transferOwnership.value.units_sold,
    },
    route.params.entity_type,
    route.params.entity_id,
  )
  await ownershipTransferStore.fetchOwnershipTransfers(route.params.entity_type, route.params.entity_id)
  await commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id)
  addTransferModal.close()
}

watch(
  transferOwnership,
  (to) => {
    if (!to) return
    const price = to.price_per_unit || 0
    const units = to.units_sold || 0
    const carried_interest_amount = to.carried_interest_amount || 0
    const other_fee = to.other_fee || 0

    ownershipTransferAggregates.value.amount_paid = price * units
    ownershipTransferAggregates.value.net =
      ownershipTransferAggregates.value.amount_paid - carried_interest_amount - other_fee

    ownershipTransferAggregates.value.sellers_purchased_units = commitmentStore.commitments.reduce(
      (total, commitment) => {
        if (commitment.investor._cid === to.seller_investor_cid) {
          total += Number(commitment.purchased_units)
        }
        return total
      },
      0,
    )
  },
  { deep: true },
)

const transferError = computed(() => {
  if (transferOwnership.value.seller_investor_cid && ownershipTransferAggregates.value.sellers_purchased_units === 0)
    return 'Seller has no units'
  if (ownershipTransferAggregates.value.sellers_purchased_units < transferOwnership.value.units_sold)
    return 'Seller does not have enough units'

  return null
})

///////////////////////////////////////////////////////////////////////////////
// REMOVE TRANSFER
///////////////////////////////////////////////////////////////////////////////

const removeTransfer = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await ownershipTransferStore.removeOwnershipTransfer(item.id, route.params.entity_type, route.params.entity_id)
  await commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id)
}

onMounted(async () => {
  await entityStore.fetchEntityInfo({
    type: route.params.entity_type as string,
    id: route.params.entity_id as string,
  })

  await Promise.all([
    vehicleStore.fetchVehicles(),
    ownershipTransferStore.fetchOwnershipTransfers(route.params.entity_type, route.params.entity_id),
    investorStore.list(),
    commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id),
  ])

  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <div class="-mt-7 mb-5 flex justify-end">
      <VButton size="md" variant="v-blue" :click="() => openAddTransferModal()" v-if="isAdmin">
        <div class="mr-1 flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>Transfer</div>
        </div>
      </VButton>
    </div>
    <VTable
      :columns="[
        {
          key: 'transfer_name',
          name: capitalize(t('shared.transfer')),
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'date',
          name: capitalize(t('shared.date')),
          sorted: true,
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'units',
          name: 'Units',
          type: 'number',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'price_per_unit',
          name: 'Price Per Unit',
          type: 'currency',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'distribution.gross',
          name: capitalize(t('shared.amount paid')),
          type: 'currency',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'distribution.carried_interest',
          name: 'Carried Interest',
          type: 'currency',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'distribution.other_fee',
          name: 'Other Fee',
          type: 'currency',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'distribution.net',
          name: 'Net Distributed',
          type: 'currency',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="currentTransfers"
      :name="`${route.params.entity_type}-${route.params.entity_id}-transfers`"
      :skeleton="skeleton"
      :slots="['actions', 'transfer_name']"
    >
      <template #transfer_name="{ item }">
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="investorPath(get(item, 'seller_commitment.investor._cid'))"
          v-if="isAdmin"
        >
          {{ get(item, 'seller_commitment.investor.name') }}
        </RouterLink>
        <span v-else> {{ get(item, 'seller_commitment.investor.name') }} </span>
        ->
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="investorPath(get(item, 'buyer_commitment.investor._cid'))"
          v-if="isAdmin"
        >
          {{ get(item, 'buyer_commitment.investor.name') }}
        </RouterLink>
        <span v-else> {{ get(item, 'buyer_commitment.investor.name') }} </span>
      </template>
      <template #actions="{ item }">
        <div class="flex gap-3">
          <VButton size="xs" :click="() => removeTransfer(item)" v-if="isAdmin">
            {{ capitalize(t('shared.remove')) }}
          </VButton>
        </div>
      </template>
    </VTable>
    <VModal :modalStore="addTransferModal">
      <template #main>
        <VSection label="Transfer Ownership">
          <form action="">
            <div class="mt-6 space-y-3">
              <VSelect
                v-model="transferOwnership.seller_investor_cid"
                :label="capitalize(t('shared.who is the seller?'))"
                :options="sellerTranferOptions"
                property="seller_investor_cid"
                :v$="vto$"
                class="z-[1]"
              />
              <VSelect
                v-model="transferOwnership.buyer_investor_cid"
                :label="capitalize(t('shared.who is the buyer?'))"
                :options="buyerTranferOptions"
                property="buyer_investor_id"
                :v$="vto$"
              />
              <VTextField
                v-model="transferOwnership.date"
                :label="capitalize(t('shared.date'))"
                type="date"
                property="date"
                :v$="vto$"
              />
              <VTextField
                v-model="ownershipTransferAggregates.sellers_purchased_units"
                label="Purchased Units"
                property="units"
                type="number"
                :v$="vto$"
                :disabled="true"
              />
              <VTextField
                v-model="transferOwnership.units_sold"
                label="How many units are being sold?"
                property="units"
                type="number"
                :v$="vto$"
              />
              <VTextField
                v-model="transferOwnership.price_per_unit"
                label="What is the price per unit?"
                property="price"
                :placeholder="n(initialMoney, 'currency')"
                :v$="vto$"
              />
              <VTextField
                v-model="ownershipTransferAggregates.amount_paid"
                :label="capitalize(t('shared.how much is being paid for it?'))"
                :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                property="amount_paid"
                type="number"
                :v$="vto$"
                :disabled="true"
              />
              <VTextField
                v-model="transferOwnership.carried_interest_amount"
                :label="capitalize(t('shared.is there any carried interest being paid?'))"
                :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                property="carried_interest_amount"
                type="number"
                :v$="vto$"
              />
              <VTextField
                v-model="transferOwnership.other_fee"
                :label="capitalize(t('shared.any other fees being paid as part of this transaction?'))"
                :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                property="other_fee"
                type="number"
                :v$="vto$"
              />
              <VTextField
                v-model="ownershipTransferAggregates.net"
                label="Net Distributed to Seller"
                :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                property="net"
                type="number"
                :v$="vto$"
                :disabled="true"
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div>
          <div class="-mt-3 mb-3 text-center text-sm text-red-500" v-if="transferError">
            {{ transferError }}
          </div>
          <VButton
            :click="transferOwnershipSubmit"
            variant="primary"
            size="lg"
            class="w-full"
            :disabled="!!transferError"
          >
            Save
          </VButton>
        </div>
      </template>
    </VModal>
  </TheLayout>
</template>
