import EntitiesPage from '../pages/entities-page.vue'
import OverviewPage from '../pages/overview-page.vue'

export default [
  {
    path: '/:slug/tax/overview',
    name: 'tax.overview',
    component: OverviewPage,
  },
  {
    path: '/:slug/tax/entities',
    name: 'tax.entities',
    component: EntitiesPage,
    meta: {},
  },
]
