<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { TheContent } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TheHeader from '@/modules/investing/components/the-header.vue'
import TheNav from '@/modules/investing/components/the-nav.vue'
import VSection from '@/modules/shared/components/v-section.vue'
import VStats from '@/modules/shared/components/v-stats.vue'
import VComparisonCard from '@/modules/shared/components/v-comparison-card.vue'
import VTopList from '@/modules/shared/components/v-top-list.vue'
// import { useEntityStore } from '../../stores/entity-store'
import { useInsightsStore } from '../../stores/insights-store'
import { useExtendedI18n } from '@/i18n'
import { useRoute } from 'vue-router'
import { scrollToSection } from '../../utils/scroll-to-section'
import { reactive } from 'vue'

const { n, t } = useExtendedI18n()
const insightsStore = useInsightsStore()
// const entityStore = useEntityStore()
const route = useRoute()
const investorSetId = route.params.entity_id


///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type EntityType = 'fund' | 'spv' | 'gp'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const formatEntityType = (type: string) => {
  const entity_types = {
    fund: 'Fund',
    spv: 'SPV',
    gp: 'GP',
  }

  return entity_types[type]
}

const skeleton = reactive({
  deal_volume_by_group: true,
  current_value_of_investments_globally: true,
  roi_by_group: true,
  roi_by_investor_set: true, 
  dpi_by_group: true,
  dpi_by_investor_set: true,
  moic_by_group: true,
  moic_by_investor_set: true, 
  rvpi_by_investor_set: true,
  rvpi_by_group: true,
  tvpi_by_investor_set: true,
  tvpi_by_group: true
})

const entity_type = route.params.entity_type as EntityType

const zeroCall = "roi_globally,dpi_globally,moic_globally,rvpi_globally,tvpi_globally"
const firstCall = "roi_by_group,dpi_by_group,dpi_by_investor_set,moic_by_group,rvpi_by_investor_set,rvpi_by_group,moic_by_investor_set,roi_by_investor_set,tvpi_by_investor_set,tvpi_by_group"
const secondCall = "average_cash_position_by_investor_set,average_cash_position_by_group"
const thirdCall = "deal_volume_by_group"
const fifthCall = "total_commitment_by_investor_set,total_fund_commitment_globally,total_spv_commitment_globally"
const sixthCall = "initial_value_of_investments_globally,average_investors_by_investor_set,current_value_of_investments_globally,number_of_flexnote_spvs_globally,number_of_traditional_spvs_globally"
const eighthCall = "total_fund_commitment_globally,total_spv_commitment_globally"
const ninthCall = "cash_position_globally"

onMounted(async () => {
  //await scrollToSection(route.hash)
  insightsStore.fetchInsights(<string>investorSetId, zeroCall).then(() =>{
    skeleton['roi_globally'] = false
    skeleton['dpi_globally'] = false
    skeleton['moic_globally'] = false
    skeleton['rvpi_globally'] = false
    skeleton['tvpi_globally'] = false
  })
  insightsStore.fetchInsights(<string>investorSetId, firstCall).then(() =>{
    skeleton['roi_by_group'] = false
    skeleton['roi_by_investor_set'] = false
    skeleton['dpi_by_group'] = false
    skeleton['dpi_by_investor_set'] = false
    skeleton['moic_by_group'] = false
    skeleton['moic_by_investor_set'] = false
    skeleton['rvpi_by_investor_set'] = false
    skeleton['rvpi_by_group'] = false
    skeleton['tvpi_by_investor_set'] = false
    skeleton['tvpi_by_group'] = false
  })
  insightsStore.fetchInsights(<string>investorSetId, secondCall).then(() =>{
     skeleton['average_cash_position_by_investor_set'] = false
     skeleton['average_cash_position_by_group'] = false
  })
  insightsStore.fetchInsights(<string>investorSetId, thirdCall).then(() =>{
    skeleton['deal_volume_by_group'] = false
  })
  insightsStore.fetchInsights(<string>investorSetId, fifthCall).then(() =>{
    skeleton['total_commitment_by_investor_set'] = false
    skeleton['total_fund_commitment_globally'] = false
    skeleton['total_spv_commitment_globally'] = false
  })
  insightsStore.fetchInsights(<string>investorSetId, sixthCall)
  insightsStore.fetchInsights(<string>investorSetId, eighthCall)
  insightsStore.fetchInsights(<string>investorSetId, ninthCall)
  //skeleton.value = false
})

function getInsightByKey(key) {
  const insight = insightsStore.allInsights.find(insight => insight.name === key);
  return insight ? insight.value : null; // Return name if found, otherwise return null
}

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <TheContent>
      <template #main>
        <VSection label="Performance Metrics">
          <div class="grid grid-cols-2 gap-px bg-white p-2 border-1">
            <VComparisonCard title="ROI" :skeleton="skeleton['roi_by_investor_set'] || skeleton['roi_by_group'] || skeleton['roi_globally']" :metricAName="`This ${entity_type}`" :metricBName="`Group ${entity_type}s`" :metricCName="`All ${entity_type}s`" :metricAValue="getInsightByKey('roi_by_investor_set')" :metricBValue="getInsightByKey('roi_by_group')" :metricCValue="getInsightByKey('roi_globally')" isPercentage="true"/>
            <VComparisonCard title="MOIC" :skeleton="skeleton['moic_by_investor_set'] || skeleton['moic_by_group'] || skeleton['moic_globally']" :metricAName="`This ${entity_type}`" :metricBName="`Group ${entity_type}s`" :metricCName="`All ${entity_type}s`" :metricAValue="getInsightByKey('moic_by_investor_set')" :metricBValue="getInsightByKey('moic_by_group')" :metricCValue="getInsightByKey('moic_globally')" />
            <!-- <VComparisonCard title="XIRR" metricAName="This Fund" metricBName="My Funds" metricCName="All Funds" :metricAValue=getInsightByKey('xirr_by_investor_set') :metricBValue=getInsightByKey('xirr_by_group') :metricCValue="getInsightByKey('xirr_globally')" /> -->
            <VComparisonCard title="TVPI" :skeleton="skeleton['tvpi_by_investor_set'] || skeleton['tvpi_by_group'] || skeleton['tvpi_globally']" :metricAName="`This ${entity_type}`" :metricBName="`Group ${entity_type}s`" :metricCName="`All ${entity_type}s`" :metricAValue="getInsightByKey('tvpi_by_investor_set')" :metricBValue="getInsightByKey('tvpi_by_group')" :metricCValue="getInsightByKey('moic_globally')" />
            <VComparisonCard title="RVPI" :skeleton="skeleton['rvpi_by_investor_set'] || skeleton['rvpi_by_group'] || skeleton['rvpi_globally']" :metricAName="`This ${entity_type}`" :metricBName="`Group ${entity_type}s`" :metricCName="`All ${entity_type}s`" :metricAValue="getInsightByKey('rvpi_by_investor_set')" :metricBValue="getInsightByKey('rvpi_by_group')" :metricCValue="getInsightByKey('rvpi_globally')" />
            <VComparisonCard title="DPI" :skeleton="skeleton['dpi_by_investor_set'] || skeleton['dpi_by_group'] || skeleton['rvpi_by_group']" :metricAName="`This ${entity_type}`" :metricBName="`Group ${entity_type}s`" :metricCName="`All ${entity_type}s`" :metricAValue="getInsightByKey('dpi_by_investor_set')" :metricBValue="getInsightByKey('dpi_by_group')" :metricCValue="getInsightByKey('dpi_globally')" />
          </div>

          <!-- <div class="grid grid-cols-2 gap-px bg-white p-2 border-1">
            <VTopList title="Top 10 Performing Industries" :elements="top10PerformingIndustries"/>
            <VTopList title="Top 10 Performing Industries" :elements="top10PerformingIndustries"/>
          </div> -->

          <VStats
            class="-mt-px"
            :skeleton="skeleton['average_cash_position_by_investor_set'] || skeleton['average_cash_position_by_group']"
            :stats="[
              {
                label: t('Average Cash position for fund/spv'),
                type: 'currency',
                value: getInsightByKey('average_cash_position_by_investor_set'),
                colspan: 2,
              },
              {
                label: t('Average Cash position by group'),
                type: 'currency',
                value: getInsightByKey('average_cash_position_by_group'),
                colspan: 2,
              },
            ]"
          />
        </VSection>

        <VSection label="Deal Flow and Volume">
          <VStats
            class="-mt-px"
            :skeleton="skeleton['deal_volume_by_group']"
            :stats="[
              {
                label: t('Deal Volume'),
                type: 'currency',
                value: getInsightByKey('deal_volume_by_group'),
                colspan: 4,
              },
            ]"
          />

        </VSection>

        <VSection label="Capital Commitments">
          <VStats
            class="-mt-px"
            :skeleton="skeleton"
            :stats="[
              {
                label: t('Total commitment in current fund/SPV'),
                type: 'currency',
                value: getInsightByKey('total_commitment_by_investor_set'),
                colspan: 2,
              },
              {
                label: t('Total commitment in funds'),
                type: 'number',
                value: getInsightByKey('total_fund_commitment_globally'),
              },
              {
                label: t('Total commitment in SPVs'),
                type: 'money',
                value: getInsightByKey('total_spv_commitment_globally'),
              },
            ]"
          />
        </VSection>

        <VSection label="Liquidity & Averages">
          <VStats
            class="-mt-px"
            :skeleton="skeleton"
            :stats="[
              {
                label: t('Average Investment size'),
                type: 'money',
                value: 0,
              },
              {
                label: t('Average investor amount'),
                type: 'number',
                value: getInsightByKey('average_investors_by_investor_set'),
              },
              {
                label: t('Average fund size'),
                type: 'money',
                value: 0,
              },
              {
                label: t('Average SPV size'),
                type: 'money',
                value: 0,
              },
            ]"
          />
        </VSection>
      </template>
      <template #aside>
        <VSection label="Navigation">
          <a href="#performance-metrics">Performance Metrics</a><br />
          <a href="#deal-flow-and-volume">Deal Flow and Volume</a><br />
          <a href="#capital-commitments">Capital Commitments</a><br />
          <a href="#fund">Fund & SPV Breakdown</a><br />
          <a href="#liquidity&averages">Liquidity & Averages</a>
        </VSection>
      </template>
    </TheContent>
  </TheLayout>
</template>
