<template>
  <div class="w-full mx-auto p-6 bg-white rounded-lg">
    <h2 class="text-2xl font-bold text-gray-800 mb-6">{{ title }}</h2>
    <ul class="space-y-4">
      <li v-for="(element, index) in JSON.parse(elements).slice(0, 10)" :key="index" class="flex items-center justify-between bg-[#3D8A9D] bg-opacity-10 p-4 rounded-md shadow-sm">
        <div class="flex items-center space-x-4">
          <span class="text-xl font-semibold text-gray-700">{{ index + 1 }}. {{ element['industry_name'] }}</span>
        </div>
        <div class="flex items-center space-x-4">
          <span class="text-lg font-semibold text-green-600">{{ element['roi'] }}%</span>
          <!-- <span class="text-lg font-semibold text-gray-700">{{ element.money }}</span> -->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    elements: Array,
  },
};
</script>
