<script setup lang="ts">
import { createReusableTemplate, useWindowSize } from '@vueuse/core'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { VIcon, VSkeletonBar } from '@/modules/shared/components'

type Segment = {
  label: string
  value: string
  style: string
  ratio: number
  is_hidden?: boolean
  segments?: Segment[]
}

const props = defineProps<{
  segments: Segment[]
  skeleton?: boolean
  expanded?: boolean
}>()

const [DefineValue, ReuseValue] = createReusableTemplate()
const segments = computed(() => props.segments.filter((segment) => !segment.is_hidden))
const getSubSegments = (segment) => (segment.segments || []).filter((segment) => !segment.is_hidden)

///////////////////////////////////////////////////////////////////////////////
// Calculate true bar widths
///////////////////////////////////////////////////////////////////////////////

const elementRefs = ref([])
const maxWidth = ref(0)
const widthMultiplier = ref(100)
const widthUnits = ref('%')
const { width } = useWindowSize()

const addRef = (el) => {
  if (el) {
    elementRefs.value.push(el)
  }
}

const calcWidth = (ratio: number, units: string) => {
  return `${ratio}${units}`
}

const rerenderWidth = async () => {
  widthMultiplier.value = 100
  widthUnits.value = '%'

  await nextTick()
  maxWidth.value = Math.max(...elementRefs.value.map((el) => el.offsetWidth))
  widthMultiplier.value = maxWidth.value
  widthUnits.value = 'px'
}

watch(props, rerenderWidth)
watch(width, rerenderWidth)

onMounted(() => {
  rerenderWidth()
  if (props.expanded) {
    expanded.value = segments.value.map((_, index) => index)
  }
})

///////////////////////////////////////////////////////////////////////////////
// Expandable data
///////////////////////////////////////////////////////////////////////////////

const expanded = ref([])

async function toggleSegment(index: number) {
  if (expanded.value.includes(index)) {
    expanded.value = expanded.value.filter((i) => i !== index)
  } else {
    expanded.value.push(index)
  }

  await rerenderWidth()
}

function isExpanded(index: number) {
  return expanded.value.includes(index)
}

function isExpandable(segment: Segment) {
  return segment.segments?.length
}
</script>

<template>
  <DefineValue v-slot="{ segment, width }">
    <td class="w-full p-0 pr-4">
      <VSkeletonBar v-if="skeleton" class="ml-2" :max="360" :min="240" />
      <div v-else class="flex w-auto items-center justify-start">
        <div :ref="(el) => addRef(el)" class="mr-2 h-4" :class="segment.style" :style="`width: ${width}`">
          <!-- bar-->
        </div>
        <div class="text-sm text-gray-700">{{ segment.value }}</div>
      </div>
    </td>
  </DefineValue>

  <table class="w-full">
    <template v-for="(segment, index) in segments" :key="index">
      <tr
        class="border-t border-gray-100"
        :class="[index === 0 ? 'border-none' : '', isExpanded(index) ? 'border-t-gray-300' : '']"
      >
        <td
          class="w-0 border-r border-gray-700 p-0 py-1 pl-4 pr-2 text-xs font-medium text-gray-700 dark:text-gray-300 sm:text-sm"
        >
          <div
            class="flex items-center justify-end space-x-1"
            :class="[isExpandable(segment) ? 'cursor-pointer' : '']"
            @click="isExpandable(segment) ? toggleSegment(index) : null"
          >
            <div v-if="isExpandable(segment)" :class="[isExpanded(index) ? '' : '-rotate-90']">
              <VIcon name="chevron_down" />
            </div>
            <div class="whitespace-nowrap">{{ segment.label }}</div>
          </div>
        </td>
        <ReuseValue :segment="segment" :width="calcWidth(widthMultiplier * segment.ratio, widthUnits)" />
      </tr>

      <tr
        v-for="(subsegment, ii) in getSubSegments(segment)"
        v-show="isExpanded(index)"
        :key="ii"
        class="bg-gray-50"
        :class="[
          isExpanded(index) ? 'border-t border-gray-100' : '',
          isExpanded(index) && segment.segments.length - 1 === ii ? 'border-b border-b-gray-300 ' : '',
        ]"
      >
        <td
          class="w-0 whitespace-nowrap border-r border-gray-700 p-0 py-1 pl-4 pr-2 text-right text-xs font-medium text-gray-700 sm:text-sm"
        >
          {{ subsegment.label }}
        </td>
        <ReuseValue
          :segment="subsegment"
          :width="calcWidth(widthMultiplier * (segment.ratio || 1) * subsegment.ratio, widthUnits)"
        />
      </tr>
    </template>
  </table>
</template>
