<script setup lang="ts">
import { capitalize, get } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useExtendedI18n } from '@/i18n'
import {
  ActionItem,
  ActionsGroup,
  ActionsMenu,
  VBadge,
  VButton,
  VDropdownImproved,
  VTable,
  VModal,
  VSection,
  VTextField,
  VTextArea,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import { useActionsMenu } from '@/modules/shared/utils/actions-menu'
import { rails_url } from '@/modules/shared/utils/rails'
import { createQueryString } from '@/modules/shared/utils/routing'
import { useInvoiceStore } from '../stores/invoice-store'
import { investorPath } from '../utils/investor'
import { format } from '@/modules/shared/utils/v-table'

const { n, t } = useExtendedI18n()
const route = useRoute()
const props = defineProps<{
  invoices: any[]
  investable_id: string
  investable_type: string
  skeleton: boolean
  enable_multiple_payment?: boolean
  hidden_columns?: string[]
  isAdmin?: boolean
}>()
const invoiceStore = useInvoiceStore()
const selectedInvoiceType = ref(route.query?.filter || 'all')
const selectedInvoices = ref<number[]>([])
const selectedInvestorKeys = ref<string[]>([])
const invoicePaymentFormModal = useModal()
const invoiceDetailsModal = useModal()
const selectedInvoice = ref(null)
const loading = ref(false)

const updateURLQuery = () => {
  const query = {}
  if (selectedInvoiceType.value !== 'all') {
    query.tab = selectedInvoiceType.value
  }
  if (selectedInvestorKeys.value.length !== investorOptions.value.length) {
    query.investor = selectedInvestorKeys.value.join(',')
  }
  const queryString = createQueryString(query)
  window.history.replaceState(null, null, `?${queryString}`)
}

const investorOptions = computed(() =>
  Object.values(
    props.invoices.reduce((acc, invoice) => {
      acc[invoice.investor._cid] = invoice.investor
      return acc
    }, {}),
  ).map((investor) => ({ label: investor.name, value: investor._cid })),
)
const viewingAs = computed(() => {
  if (selectedInvestorKeys.value.length === investorOptions.value.length) {
    return `all ${t('shared.profile', 0)}`
  }

  if (selectedInvestorKeys.value.length === 1) {
    return investorOptions.value.find((option) => option.value === selectedInvestorKeys.value[0]).label
  }

  if (selectedInvestorKeys.value.length > 1 || selectedInvestorKeys.value.length < 1) {
    return `${selectedInvestorKeys.value.length} ${t('shared.investor', selectedInvestorKeys.value.length)}`
  }
})

const hidden_columns = computed(() => props.hidden_columns || [])
const toggleAllInvoice = (e) => {
  if (e.target.checked) {
    selectedInvoices.value = current_invoices.value.map((invoice) => invoice.id)
  } else {
    selectedInvoices.value = []
  }
}
const toggleInvoiceSelection = (id: number) => {
  mark_single_invoice.value = false
  if (selectedInvoices.value.includes(id)) {
    selectedInvoices.value = selectedInvoices.value.filter((invoiceId) => invoiceId !== id)
  } else {
    selectedInvoices.value.push(id)
  }
}
const selectInvoiceType = (type: string) => {
  selectedInvoiceType.value = type
  updateURLQuery()
}

const current_invoices = computed(() =>
  props.invoices
    .filter((invoice) => {
      if (selectedInvoiceType.value === 'all') {
        return true
      }

      if (selectedInvoiceType.value === 'outstanding') {
        return !invoice.marked_as_paid
      }

      if (selectedInvoiceType.value === 'paid') {
        return invoice.marked_as_paid
      }
    })
    .filter((invoice) => {
      if (invoice.investor._cid.startsWith('funding-entity')) {
        return (
          selectedInvestorKeys.value.includes(invoice.investor._cid) ||
          selectedInvestorKeys.value.includes(invoice.investor._cid.replace('funding-entity', 'associated-entity'))
        )
      } else {
        return selectedInvestorKeys.value.includes(invoice.investor._cid)
      }
    }),
)
const outstanding_invoice_ids = computed(() =>
  props.invoices.filter((invoice) => !invoice.marked_as_paid).map((invoice) => invoice.id),
)
const paid_invoice_ids = computed(() =>
  props.invoices.filter((invoice) => invoice.marked_as_paid).map((invoice) => invoice.id),
)

const initialState = {
  invoice_ids: [],
  paid_at: null,
  instructions: null,
}
const rules = {
  invoice_ids: { required },
  paid_at: { required },
}
const invoicePaymentForm = ref({ ...initialState })
const v$ = useVuelidate(rules, invoicePaymentForm, { $lazy: true })
const actionsMenu = useActionsMenu(['send_reminder', 'mark_as_paid', 'mark_as_unpaid', 'delete'])
const mark_single_invoice = ref(false)

function resetSelectedInvoiceIds() {
  selectedInvoices.value = []
}

const openInvoicePaymentForm = () => {
  mark_single_invoice.value = false
  const invoice_ids = selectedInvoices.value.filter((invoice_id) => outstanding_invoice_ids.value.includes(invoice_id))
  if (invoice_ids.length === 0) return alert('Please select at least one outstanding invoice to mark as paid')
  invoicePaymentFormModal.open()
}

const submitInvoicePaymentForm = async () => {
  invoicePaymentForm.value.invoice_ids = selectedInvoices.value.filter((invoice_id) =>
    outstanding_invoice_ids.value.includes(invoice_id),
  )
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true
  await invoiceStore.markMultipleInvoicesAsPaid(invoicePaymentForm.value)
  await invoiceStore.fetchInvoices(props.investable_type, props.investable_id)
  loading.value = false
  invoicePaymentFormModal.close()

  // reset
  invoicePaymentForm.value = { ...initialState }
  selectedInvoices.value = []
}

const markInvoicesAsUnpaid = async () => {
  const invoice_ids = selectedInvoices.value.filter((invoice_id) => paid_invoice_ids.value.includes(invoice_id))
  if (invoice_ids.length === 0) return alert('Please select at least one paid invoice to mark as unpaid')

  const confirm = window.confirm('Are you sure you want to mark the selected invoices as unpaid?')
  if (!confirm) return

  loading.value = true
  await invoiceStore.markMultipleInvoicesAsUnpaid(invoice_ids)
  await invoiceStore.fetchInvoices(props.investable_type, props.investable_id)
  loading.value = false

  resetSelectedInvoiceIds()
}

const sendReminder = async (invoice) => {
  actionsMenu.setLoading('send_reminder', true)
  await invoiceStore.sendReminder(invoice.id)
  actionsMenu.setLoading('send_reminder', false)
}

const markInvoiceAsUnpaid = async (invoice) => {
  const confirm = window.confirm('Are you sure you want to mark the invoice as unpaid?')
  if (!confirm) return

  actionsMenu.setLoading('mark_as_unpaid', true)
  await invoiceStore.markInvoiceAsUnpaid(invoice.id)
  await invoiceStore.fetchInvoices(props.investable_type, props.investable_id)
  actionsMenu.setLoading('mark_as_unpaid', false)
}

const markInvoiceAsPaid = (invoice) => {
  mark_single_invoice.value = true
  selectedInvoices.value = [invoice.id]
  invoicePaymentFormModal.open()
}

const removeInvoice = async (invoice) => {
  const confirm = window.confirm('Are you sure you want to delete the invoice?')
  if (!confirm) return

  actionsMenu.setLoading('delete', true)
  await invoiceStore.removeInvoice(invoice.id)
  await invoiceStore.fetchInvoices(props.investable_type, props.investable_id)
  actionsMenu.setLoading('delete', false)
}

watch(
  () => props.skeleton,
  (v) => {
    if (route.query.tab) {
      selectedInvoiceType.value = route.query.tab
    }

    if (route.query.investor || route.query.investor === '') {
      selectedInvestorKeys.value = route.query.investor.split(',')
    } else {
      selectedInvestorKeys.value = Object.values(
        props.invoices.reduce((acc, invoice) => {
          acc[invoice.investor._cid] = invoice.investor._cid
          return acc
        }, {}),
      )
    }

    if (route.query.invoice_id) {
      openInvoiceDetails(route.query.invoice_id)
    }
  },
)
const openInvoiceDetails = (invoice_id) => {
  selectedInvoice.value = props.invoices.find((i) => parseInt(i.id) === parseInt(invoice_id))
  window.history.replaceState(null, null, `?invoice_id=${invoice_id}`)
  invoiceDetailsModal.open()
}
</script>

<template>
  <div class="relative z-50 mb-3 flex items-center justify-between">
    <div class="flex items-center space-x-1.5">
      <VButton :active="selectedInvoiceType === 'all'" class="w-32" @click="selectInvoiceType('all')">
        <span>{{ capitalize(t('shared.all')) }}</span>
      </VButton>
      <VButton :active="selectedInvoiceType === 'outstanding'" class="w-32" @click="selectInvoiceType('outstanding')">
        <span>{{ capitalize(t('shared.outstanding')) }}</span>
      </VButton>
      <VButton :active="selectedInvoiceType === 'paid'" class="w-32" @click="selectInvoiceType('paid')">
        <span>{{ capitalize(t('shared.paid')) }}</span>
      </VButton>
      <VDropdownImproved
        v-model="selectedInvestorKeys"
        :onChange="updateURLQuery"
        aligned="left"
        :options="investorOptions"
        class="z-[1]"
      >
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Filter by {{ viewingAs }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
    </div>
    <div class="flex items-center space-x-1.5" v-if="props.enable_multiple_payment">
      <VButton size="sm" variant="v-blue" :loading="loading" :click="markInvoicesAsUnpaid">
        {{ capitalize(t('shared.mark as unpaid')) }}
      </VButton>
      <VButton size="sm" variant="v-blue" :disabled="loading" :click="openInvoicePaymentForm">
        {{ capitalize(t('shared.mark as paid')) }}
      </VButton>
    </div>
  </div>
  <VTable
    :columns="[
      {
        key: 'checkbox',
        name: '',
        type: 'string',
        align: 'left',
        is_visible: true,
        is_accessible: props.enable_multiple_payment,
        enable_thead_slot: true,
      },
      {
        key: 'name',
        name: capitalize(t('shared.name')),
        type: 'string',
        align: 'left',
        fixed: true,
        is_visible: !hidden_columns.includes('name'),
      },
      {
        key: 'investor.name',
        name: capitalize(t('shared.investor')),
        type: 'string',
        align: 'left',
        is_visible: !hidden_columns.includes('investor'),
      },
      {
        key: 'date',
        name: capitalize(t('shared.due date')),
        type: 'date',
        sorted: true,
        align: 'left',
        is_visible: !hidden_columns.includes('date'),
      },
      {
        key: 'marked_as_paid',
        name: capitalize(t('shared.status')),
        type: 'boolean',
        align: 'left',
        is_visible: !hidden_columns.includes('status'),
      },
      {
        key: 'total_amount',
        name: capitalize(t('shared.total amount due')),
        type: 'currency',
        align: 'right',
        is_visible: !hidden_columns.includes('total_amount'),
        aggregate: 'sum',
      },
      {
        key: 'paid_at',
        name: capitalize(t('shared.paid date')),
        type: 'date',
        align: 'left',
        is_visible: !hidden_columns.includes('paid_at'),
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: isAdmin,
      },
    ]"
    :items="current_invoices"
    :name="`${investable_type}-${investable_id}-invoices`"
    :skeleton="skeleton"
    :slots="['actions', 'investor.name', 'invoice_number', 'name', 'marked_as_paid', 'checkbox']"
  >
    <template #checkbox="{ item }">
      <input
        type="checkbox"
        :checked="selectedInvoices.includes(item.id) && !mark_single_invoice"
        @change="toggleInvoiceSelection(item.id)"
      />
    </template>
    <template #investor.name="{ item }">
      <RouterLink
        class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
        :to="investorPath(get(item, 'investor._cid'))"
      >
        {{ get(item, 'investor.name') }}
      </RouterLink>
    </template>
    <template #invoice_number="{ item }">
      <a class="hyperlink" :href="`${rails_url()}/invoices/${get(item, 'id')}`"> #{{ get(item, 'id') }} </a>
    </template>
    <template #name="{ item }">
      <div class="hyperlink" @click="() => openInvoiceDetails(item.id)">
        {{ get(item, 'name') }}
      </div>
    </template>
    <template #marked_as_paid="{ item }">
      {{ item.marked_as_paid ? 'paid' : 'outstanding' }}
    </template>
    <template #actions="{ item }">
      <div class="flex items-center justify-end space-x-1.5">
        <ActionsMenu v-if="isAdmin">
          <ActionsGroup>
            <ActionItem
              v-if="!item.marked_as_paid"
              :text="capitalize(t('shared.send reminder'))"
              @click="() => sendReminder(item)"
              :loading="actionsMenu.actions.value.send_reminder?.loading"
            />
            <ActionItem
              v-if="!item.marked_as_paid"
              :text="capitalize(t('shared.mark as paid'))"
              @click="() => markInvoiceAsPaid(item)"
            />
            <ActionItem
              v-else
              :text="capitalize(t('shared.mark as unpaid'))"
              @click="() => markInvoiceAsUnpaid(item)"
              :loading="actionsMenu.actions.value.mark_as_unpaid?.loading"
            />
            <ActionItem
              :text="capitalize(t('shared.delete'))"
              @click="() => removeInvoice(item)"
              :loading="actionsMenu.actions.value.delete?.loading"
            />
          </ActionsGroup>
        </ActionsMenu>
      </div>
    </template>
  </VTable>
  <VModal :modalStore="invoicePaymentFormModal">
    <template #main>
      <VSection :label="mark_single_invoice ? 'Mark invoice as paid' : 'Mark multiple invoices as paid'">
        <form @submit.prevent="submitInvoicePaymentForm">
          <div class="mt-6 space-y-3">
            <VTextField
              v-model="invoicePaymentForm.paid_at"
              :label="capitalize(t('shared.payment date'))"
              property="paid_at"
              ref="autofocus"
              type="date"
              :v$="v$"
            />
            <VTextArea
              :label="capitalize(t('shared.Intructions / Notes'))"
              v-model="invoicePaymentForm.instructions"
              :v$="v$"
              property="instructions"
            />
          </div>
        </form>
      </VSection>
    </template>
    <template #footer>
      <div class="flex items-center justify-between space-x-3">
        <VButton :click="invoicePaymentFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
        <VButton :click="submitInvoicePaymentForm" class="w-full" size="lg" :loading="loading" variant="primary">
          {{ capitalize(t('shared.submit')) }}
        </VButton>
      </div>
    </template>
  </VModal>
  <VModal :modalStore="invoiceDetailsModal" :has_footer="false">
    <template #main v-if="selectedInvoice">
      <div class="mb-5 text-2xl font-bold">{{ selectedInvoice.name }}</div>
      <h3 class="text-lg font-semibold leading-7 text-gray-700 dark:text-gray-300">Invoice Overview</h3>
      <div class="mb-3 mt-1 border-b-[1px] border-gray-300 pb-3">
        <span class="text-lg font-bold text-[#3b88af]">{{ n(selectedInvoice.total_amount, 'currency') }}</span>
        <span> due on {{ format(selectedInvoice.date, 'date') }}</span>
      </div>
      <table>
        <tbody>
          <tr>
            <td>Capital available for Investment</td>
            <td class="font-semibold">{{ n(selectedInvoice.amount, 'currency') }}</td>
          </tr>
          <tr>
            <td>Management Fees</td>
            <td class="font-semibold">{{ n(selectedInvoice.management_fees, 'currency') }}</td>
          </tr>
          <tr>
            <td>Other Fees</td>
            <td class="font-semibold">{{ n(selectedInvoice.other_fees, 'currency') }}</td>
          </tr>
          <tr>
            <td>Total Due</td>
            <td class="font-semibold">{{ n(selectedInvoice.total_amount, 'currency') }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <VBadge
                :color="selectedInvoice.marked_as_paid ? 'emerald' : 'red'"
                class="ml-auto w-fit !rounded-full px-5 py-0.5 text-sm"
                size="custom"
              >
                {{ selectedInvoice.marked_as_paid ? 'Paid' : 'Unpaid' }}
              </VBadge>
            </td>
          </tr>
          <tr v-if="selectedInvoice.paid_at">
            <td>Paid Date</td>
            <td class="font-semibold">{{ format(selectedInvoice.paid_at, 'date') }}</td>
          </tr>
          <tr v-if="selectedInvoice.entity">
            <td>Entity</td>
            <td>
              <RouterLink
                class="hyperlink"
                :to="{
                  name: 'investing.entity-overview',
                  params: { entity_type: selectedInvoice.entity.entity_type, entity_id: selectedInvoice.entity.id },
                }"
                >{{ selectedInvoice.entity.name }}</RouterLink
              >
            </td>
          </tr>
          <tr>
            <td>Investor</td>
            <td>
              <RouterLink
                class="hyperlink"
                :to="{
                  name: 'investing.individual-overview',
                  params: { individual_id: selectedInvoice.investor.id },
                }"
                >{{ selectedInvoice.investor.name }}</RouterLink
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="selectedInvoice.wiring_instructions">
        <h3
          class="mb-3 mt-5 border-b-[1px] border-gray-300 pb-3 text-lg font-semibold leading-7 text-gray-700 dark:text-gray-300"
        >
          Wiring Instructions
        </h3>
        <div class="mb-2 whitespace-pre-line">{{ selectedInvoice.wiring_instructions }}</div>
      </div>
    </template>
  </VModal>
</template>

<style scoped>
table td {
  @apply whitespace-nowrap pb-2 pr-5  first:w-[1%];
}
table td:nth-child(1) {
  @apply text-left;
}
table td:nth-child(2) {
  @apply text-right;
}
</style>
