<script setup lang="ts">
import { computed, useSlots } from 'vue'

const props = defineProps<{
  label?: string
  label_class?: string
}>()

const slots = useSlots()

const hasLabel = computed(() => !!slots.label || !!props.label)

const snakeCase = string => {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
};

function create_id(label: string) {
  return label.toLowerCase().replace(/ /g, '-')
}
</script>

<template>
  <section class="mb-8 sm:mb-12">
    <div
      v-if="hasLabel"
      class="mb-6 flex items-center justify-between border-b border-gray-200 px-0 pb-1 dark:border-zinc-800"
    >
      <div>
        <h3
          v-if="label"
          :id="create_id(label)"
          class="font-medium leading-7 text-gray-700 dark:text-gray-300"
          :class="label_class"
        >
          <a :href="`#${create_id(label)}`" tabindex="-1">{{ label }}</a>
        </h3>
        <slot name="label"></slot>
      </div>
      <div>
        <slot name="actions"></slot>
      </div>
    </div>
    <slot></slot>
  </section>
</template>
