import { useAuthStore } from '@/modules/auth/stores/auth-store'

interface FetchOptions {
  headers?: Record<string, string>
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
}

export const useFetch = (url: string, options?: FetchOptions) => {
  const baseUrl = import.meta.env.VITE_VENTURE360_API_URL.replace('/v3', '')
  const authStore = useAuthStore()
  const baseOptions = {
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
      'Content-Type': 'application/json',
    },
  }

  const get = async <T>(): Promise<T> => {
    const res = await fetch(`${baseUrl}${url}`, baseOptions)

    if (!res.ok) {
      console.error(await res.json())
      throw new Error()
    }

    return (await res.json()) as T
  }

  const post = async <T>(body?: unknown): Promise<T> => {
    const res = await fetch(`${baseUrl}${url}`, {
      ...baseOptions,
      method: 'POST',
      body: body ? JSON.stringify(body) : undefined,
    })

    if (!res.ok) {
      console.error(await res.json())
      throw new Error()
    }

    return (await res.json()) as T
  }

  return { get, post }
}
