<script setup lang="ts">
import { VButton, VIcon, VButtonInvisible, VLoadingSection } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import DisbursementForm from '../components/disbursement-form.vue'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisbursementStore } from '../stores/disbursement-store'
import { useEntityStore } from '../stores/entity-store'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const skeleton = ref(true)
const disbursementStore = useDisbursementStore()
const entityStore = useEntityStore()
const currentEntity = ref(null)
const disbursement = ref(null)
const loading = ref(false)
const disbursementForm = ref(null)

const addDisbursement = () => {
  if (disbursementForm.value) {
    disbursementForm.value.submit()
  }
}

const submit = async (payload) => {
  loading.value = true
  await disbursementStore.addDisbursement(payload)
  router.push({
    name: 'investing.distributions',
    params: { entity_id: route.params.entity_id, entity_type: route.params.entity_type },
  })
}

onMounted(async () => {
  await Promise.all([
    disbursementStore.fetchDisbursement(
      route.params.entity_type,
      route.params.entity_id,
      null,
      route.query.portfolio_disbursement_allocation_id,
    ),
    entityStore.fetchEntityInfo({ type: route.params.entity_type as string, id: route.params.entity_id as string }),
  ])
  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  disbursement.value = disbursementStore.items.get('undefined:null') // this is the cid if id is null
  skeleton.value = false
})
</script>

<template>
  <div>
    <div class="fixed left-0 right-0 z-50 block bg-white/5 backdrop-blur" :class="isBannerVisible ? 'top-8' : 'top-0'">
      <div class="h-1 bg-gradient-to-r from-[#52B7B9] to-[#3783B0] opacity-90"></div>
    </div>
    <!-- nav -->
    <div
      class="sticky top-1 z-50 flex items-center justify-between border-b bg-white/50 px-6 py-4 shadow-sm backdrop-blur"
    >
      <div class="left flex items-center space-x-3">
        <RouterLink
          :to="{
            name: 'investing.distributions',
            params: { entity_id: route.params.entity_id, entity_type: route.params.entity_type },
          }"
        >
          <VButtonInvisible><VIcon name="x" /></VButtonInvisible>
        </RouterLink>
        <h3 class="font-medium leading-7 text-gray-700 dark:text-gray-300">New Distribution</h3>
      </div>
      <div class="right flex items-center space-x-1.5">
        <RouterLink
          :to="{
            name: 'investing.distributions',
            params: { entity_id: route.params.entity_id, entity_type: route.params.entity_type },
          }"
        >
          <VButton>Cancel</VButton>
        </RouterLink>
        <VButton :loading="loading" variant="v-blue" class="px-8" @click="addDisbursement">Save distribution</VButton>
      </div>
    </div>
    <!-- main form -->
    <div class="p-6">
      <VLoadingSection
        v-if="skeleton"
        class="mt-40"
        title="Preparing Your Form..."
        description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs
      smoothly."
      />
      <DisbursementForm
        v-else
        ref="disbursementForm"
        :disbursement="disbursement"
        :entity="currentEntity"
        @submit="submit"
      />
    </div>
  </div>
</template>
