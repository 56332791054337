<script setup lang="ts">
import { onMounted, ref } from 'vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { TheContent } from '@/modules/shared/components'
import VSection from '@/modules/shared/components/v-section.vue'
import VStats from '@/modules/shared/components/v-stats.vue'
import VComparisonCard from '@/modules/shared/components/v-comparison-card.vue'
import VTopList from '@/modules/shared/components/v-top-list.vue'
import { useInsightsStore } from '@/modules/investing/stores/insights-store'
import { useExtendedI18n } from '@/i18n'
import { useRoute } from 'vue-router'
import { reactive } from 'vue'

const { n, t } = useExtendedI18n()
const insightsStore = useInsightsStore()
const route = useRoute()
const investorSetId = route.params.entity_id
// TODO: change this
const skeleton = reactive({
  deal_volume_by_group: true,
  average_current_value_of_investments_globally: true,
  average_cash_position_by_investor_set: true,
  moic_globally: true,
  roi_spv_globally: true,
  roi_fund_globally: true,
  rvpi_globally: true,
  tvpi_globally: true,
  dpi_globally: true,
  roi_all_industries: true,
  deal_volume_globally: true,
  average_cash_position_by_group: true,
  average_initial_value_of_investments_globally: true,
  average_fund_commitment_globally: true,
  total_spv_commitment_globally: true,
  number_of_flexnote_spvs_globally: true,
  number_of_traditional_spvs_globally: true,
  number_of_funds_globally: true,
})

const calls = [
  "deal_volume_by_group",
  "average_current_value_of_investments_globally",
  "roi_spv_globally,roi_fund_globally,moic_spv_globally,moic_fund_globally,tvpi_spv_globally,tvpi_fund_globally,rvpi_spv_globally,rvpi_fund_globally,dpi_fund_globally,dpi_spv_globally",
  "average_cash_position_by_group",
  "average_initial_value_of_investments_globally",
  "average_fund_commitment_globally,total_spv_commitment_globally",
  "number_of_flexnote_spvs_globally,number_of_traditional_spvs_globally,number_of_funds_globally",
  "deal_volume_globally",
  "roi_all_industries",
  "average_cash_position_by_investor_set",
]


onMounted(async () => {
  //await scrollToSection(route.hash)
  calls.forEach((call, index) => {
    insightsStore.fetchInsights(<string>investorSetId, call).then(() => {
      call.split(',').forEach(key => {
      skeleton[key] = false
      })
    })
  })
})

function getInsightByKey(key) {
  const insight = insightsStore.allInsights.find(insight => insight.name === key);
  return insight ? insight.value : null; // Return name if found, otherwise return null
}

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////
</script>

<template>
  <TheLayout>
    <TheContent>
      <template #main>
        <VSection label="Performance Metrics">
          <div class="grid grid-cols-2 gap-px bg-white p-2 border-1">
            <VComparisonCard :skeleton="skeleton['roi_spv_globally'] && skeleton['roi_fund_globally']" title="ROI" metricAName="Funds"  :metricAValue="getInsightByKey('roi_fund_globally')" metricBName="SPVs" :metricBValue="getInsightByKey('roi_spv_globally')" isPercentage="true" useMainValue="false" />
            <VComparisonCard :skeleton="skeleton['moic_spv_globally'] && skeleton['moic_fund_globally']" title="MOIC" metricAName="Funds" :metricAValue="getInsightByKey('moic_fund_globally')" metricBName="SPVs" :metricBValue="getInsightByKey('moic_spv_globally')" useMainValue="false" />
            <!-- <VComparisonCard title="XIRR" metricAName="This Fund" metricBName="My Funds" metricCName="All Funds" :metricAValue=getInsightByKey('xirr_by_investor_set') :metricBValue=getInsightByKey('xirr_by_group') :metricCValue="getInsightByKey('xirr_globally')" /> -->
            <VComparisonCard :skeleton="skeleton['tvpi_spv_globally'] && skeleton['tvpi_fund_globally']" title="TVPI" metricAName="Funds" :metricAValue="getInsightByKey('tvpi_fund_globally')" metricBName="SPVs" :metricBValue="getInsightByKey('tvpi_spv_globally')" useMainValue="false" />
            <VComparisonCard :skeleton="skeleton['rvpi_spv_globally'] && skeleton['rvpi_fund_globally']" title="RVPI" metricAName="Funds" :metricAValue="getInsightByKey('rvpi_fund_globally')" metricBName="SPVs" :metricBValue="getInsightByKey('rvpi_spv_globally')" useMainValue="false" />
            <VComparisonCard :skeleton="skeleton['dpi_spv_globally'] && skeleton['dpi_fund_globally']" title="DPI" metricAName="Funds"  :metricAValue="getInsightByKey('dpi_fund_globally')" metricBName="SPVs" :metricBValue="getInsightByKey('dpi_spv_globally')" useMainValue="false" />
          </div>

          <div class="grid grid-cols-1 gap-px bg-white p-2 border-1">
            <VTopList title="Top 10 Performing Industries (ROI)" :elements="getInsightByKey('roi_all_industries')"/>
          </div>

          <VStats
            class="-mt-px"
            :skeleton="skeleton['average_cash_position_by_investor_set'] || skeleton['average_cash_position_by_group']"
            :stats="[
              {
                label: t('Average Cash position by fund/spv'),
                type: 'currency',
                value: getInsightByKey('average_cash_position_by_investor_set'),
                colspan: 2,
              },
              {
                label: t('Average Cash position by group'),
                type: 'currency',
                value: getInsightByKey('average_cash_position_by_group'),
                colspan: 2,
              },
            ]"
          />
        </VSection>

        <VSection label="Deal Flow and Volume">
          <VStats
            class="-mt-px"
            :skeleton="skeleton['deal_volume_globally']"
            :stats="[
              {
                label: t('Global Deal Volume'),
                type: 'currency',
                value: getInsightByKey('deal_volume_globally'),
                colspan: 4,
              },
            ]"
          />

        </VSection>

        <VSection label="Capital Commitments">
          <VStats
            class="-mt-px"
            :skeleton="skeleton['average_fund_commitment_globally'] || skeleton['total_spv_commitment_globally']"
            :stats="[
              {
                label: t('Average commitment in funds'),
                type: 'number',
                value: getInsightByKey('average_fund_commitment_globally'),
                colspan: 2,
              },
              {
                label: t('Average commitment in SPVs'),
                type: 'money',
                value: getInsightByKey('total_spv_commitment_globally'),
                colspan: 2,
              },
            ]"
          />
        </VSection>

        <VSection label="Investment Valuation">
          <VStats
            class="-mt-px"
            :skeleton="skeleton['average_initial_value_of_investments_globally'] || skeleton['average_current_value_of_investments_globally']"
            :stats="[
              {
                label: t('Average initial value of all investments'),
                type: 'currency',
                value: getInsightByKey('average_initial_value_of_investments_globally'),
                colspan: 2,
              },
              {
                label: t('Average current value of all investments'),
                type: 'number',
                value: getInsightByKey('average_current_value_of_investments_globally'),
                colspan: 2,
              },
            ]"
          />
        </VSection>

        <VSection label="Fund & SPV Breakdown">
          <VStats
            class="-mt-px"
            :skeleton="skeleton['number_of_flexnote_spvs_globally'] ||skeleton['number_of_traditional_spvs_globally'] ||skeleton['number_of_funds_globally'] "
            :stats="[
              {
                label: t('Number of Flexnote SPVs'),
                type: 'number',
                value: getInsightByKey('number_of_flexnote_spvs_globally'),
                colspan: 2,
              },
              {
                label: t('Number of Traditional SPVs'),
                type: 'number',
                value: getInsightByKey('number_of_traditional_spvs_globally'),
              },
              {
                label: t('Number of Funds'),
                type: 'number',
                value: getInsightByKey('number_of_funds_globally'),
              },
            ]"
          />
        </VSection>

        <!-- <VSection label="Liquidity & Averages">
          <VStats
            class="-mt-px"
            :skeleton="skeleton"
            :stats="[
              {
                label: t('Average Investment size'),
                type: 'money',
                value: 0,
              },
              {
                label: t('Average investor amount'),
                type: 'number',
                value: getInsightByKey('average_investors_by_investor_set'),
              },
              {
                label: t('Average fund size'),
                type: 'money',
                value: 0,
              },
              {
                label: t('Average SPV size'),
                type: 'money',
                value: 0,
              },
            ]"
          />
        </VSection> -->
      </template>
      <template #aside>
        <VSection label="Navigation">
          <a href="#performance-metrics">Performance Metrics</a><br />
          <a href="#deal-flow-and-volume">Deal Flow and Volume</a><br />
          <a href="#capital-commitments">Capital Commitments</a><br />
          <a href="#investment-valuation">Investment Valuation</a><br />
          <a href="#fund">Fund & SPV Breakdown</a><br />
          <!-- <a href="#liquidity&averages">Liquidity & Averages</a> -->
        </VSection>
      </template>
    </TheContent>
  </TheLayout>
</template>
