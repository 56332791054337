<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { rails_url } from '@/modules/shared/utils/rails'
import { RouterLinkBack, VButton, VButtonInvisible, VLoading, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import PaymentMethodCard from '../components/payment-method-card.vue'
import SubscriptionCard from '../components/subscription-card.vue'
import SubscriptionScheduleCard from '../components/subscription-schedule-card.vue'
import VStat from '../components/v-stat.vue'
import ViewInStripeButton from '../components/view-in-stripe-button.vue'
import { useCustomerStore } from '../stores/customer-store'
import { useProductStore } from '../stores/product-store'
import { to_currency } from '../utils'
import {
  invoice_amount_remaining,
  invoice_subtotal,
  is_invoice_open,
  is_sub,
  is_sub_canceled,
  is_sub_invoice,
  is_sub_sched,
  is_sub_trialing,
  subscription_upcoming_invoice_subtotal,
  subscriptions_has_any_discount,
  is_pm_default,
} from '../utils/stripe'

const route = useRoute()
const authStore = useAuthStore()
const customerStore = useCustomerStore()
const productStore = useProductStore()

///////////////////////////////////////////////////////////////////////////////
// Keyboard shortcuts
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const ready = ref(false)
const items = ref(null)
const is_payment_loading = ref(false)

const default_payment_method_id = computed(() => {
  const paymentMethodsByBillable = customerStore.customer.stripe_payment_methods.filter(
    (pm) => pm.metadata?.billables?.match(`${billable.type}:${billable.id}`),
  )

  if (paymentMethodsByBillable.length > 0) {
    return paymentMethodsByBillable[0].id
  }

  return customerStore.customer.invoice_settings.default_payment_method
})

const createBillable = (route) => ({
  id: route.params.billable_id,
  type: route.params.billable_type,
})

const billable = createBillable(route)

const assignDefaults = () => {
  ready.value = true

  // Build list representing the current plan for the billable
  // NOTE: these steps are both more efficient and less efficient, but it's easy to read and that's most important
  // 1. select subscription schedules for the "billable"
  // 2. create map between product name and subscription schedule id (schedule only references price_id)
  // 3. select subscriptions for the "billable"
  // 4. expand subscription items into separate "subscriptions" (enables sorting by name across subscriptions)
  // 5. create map between product name and subscription id
  // 6. combine subscription schedules and subscriptions
  // 7. sort combined array by product name

  // #1, #2
  // prettier-ignore
  const stripe_subscription_schedules = customerStore.customer?.stripe_subscription_schedules?.filter((subscription_schedule) => (
      subscription_schedule.metadata.billable_id === billable.id &&
      subscription_schedule.metadata.billable_type === billable.type
    ))
    .map((subscription_schedule) => {
      return {
        id: subscription_schedule.id,
        product_id: productStore.price_to_product_lookup[subscription_schedule.phases[0].items[0].price],
        subscription_schedule
      }
    })

  // #3, #4, #5
  // prettier-ignore
  const stripe_subscriptions = customerStore.customer?.stripe_subscriptions?.filter((subscription) => (
      subscription.metadata.billable_id === billable.id &&
      subscription.metadata.billable_type === billable.type
    ))
    .map((subscription) => {
      return subscription.items.map((item) => {
        return {
          id: subscription.id,
          product_id: item.price.product,
          subscription: {
            ...subscription,
            items: [item]
          }
        }
      })
    })
    .flat()

  // needed for sorting
  const products = productStore.products

  // #6, #7
  items.value = [...stripe_subscription_schedules, ...stripe_subscriptions].sort((a, b) => {
    const product_a = products[a.product_id].name.toUpperCase()
    const product_b = products[b.product_id].name.toUpperCase()
    if (product_a < product_b) {
      return -1
    }
    if (product_a > product_b) {
      return 1
    }
    return 0
  })
}

const pay_now_classes = ref(undefined)
const payment_error = ref(undefined)
const payment_error_class = ref('hidden')
const is_payment_success = ref(false)

const pay = async () => {
  is_payment_loading.value = true
  // Send the subscription ID filtered by billable ID and Type
  const subscription_id = customerStore.customer.stripe_subscriptions
    .filter((elem) => elem.metadata.billable_id == billable.id && elem.metadata.billable_type == billable.type)
    .slice(-1)[0].stripe_subscription_id

  const response = await customerStore.pay_subscription(subscription_id)

  if (response !== undefined && response.response.status !== 201) {
    pay_now_classes.value = 'bg-red-600'
    payment_error.value = response.response.data.data.message
    payment_error_class.value = ''
    is_payment_success.value = false
  } else {
    pay_now_classes.value = 'bg-sky-600'
    is_payment_success.value = true
    payment_error.value = undefined
    payment_error_class.value = 'hidden'
  }
  is_payment_loading.value = false
}

const has_unpaid_subscriptions = computed(() => {
  // prettier-ignore
  const stripe_subscriptions = customerStore.customer?.stripe_subscriptions?.filter((subscription) => (
      subscription.metadata.billable_id === billable.id &&
      subscription.metadata.billable_type === billable.type
    ))

  if (stripe_subscriptions.some((subscription) => is_sub_trialing(subscription))) return true

  const invoices = customerStore.customer.stripe_invoices.filter(is_invoice_open).filter((invoice) => {
    return stripe_subscriptions.some((sub) => is_sub_invoice(sub, invoice))
  })

  return invoices.length !== 0
})

const formatted_invoice_amount_remaining = computed(() => {
  // prettier-ignore
  const stripe_subscriptions = customerStore.customer?.stripe_subscriptions?.filter((subscription) => (
      subscription.metadata.billable_id === billable.id &&
      subscription.metadata.billable_type === billable.type
    ))

  const total_amount_of_trialing_subscription = stripe_subscriptions
    .filter(is_sub_trialing)
    .reduce((total, subscription) => total + subscription.stripe_upcoming_invoice_cache.amount_remaining, 0)

  const invoices = customerStore.customer.stripe_invoices.filter(is_invoice_open).filter((invoice) => {
    return stripe_subscriptions.some((sub) => is_sub_invoice(sub, invoice))
  })

  if (is_payment_success.value) return '$0'

  const amount = invoice_amount_remaining(invoices) + total_amount_of_trialing_subscription
  const currency = invoices[0]?.stripe_cache?.currency || 'usd'

  return to_currency(amount, currency)
})

const formatted_invoice_subtotal_amount = computed(() => {
  if (is_payment_success.value) return null

  // prettier-ignore
  const stripe_subscriptions = customerStore.customer.stripe_subscriptions
    .filter((subscription) => (
      subscription.metadata.billable_id === billable.id &&
      subscription.metadata.billable_type === billable.type
    ))

  if (!subscriptions_has_any_discount(stripe_subscriptions)) return null

  const trialing_subscriptions = stripe_subscriptions.filter(is_sub_trialing)

  const invoices = customerStore.customer.stripe_invoices.filter(is_invoice_open).filter((invoice) => {
    return stripe_subscriptions.some((sub) => is_sub_invoice(sub, invoice))
  })

  const amount = invoice_subtotal(invoices) + subscription_upcoming_invoice_subtotal(trialing_subscriptions)
  const currency = invoices[0]?.stripe_cache?.currency || 'usd'

  return to_currency(amount, currency)
})

const onSetDefaultPaymentMethod = async (payment_method_id) => {
  await customerStore.set_payment_method_as_default(payment_method_id, billable)
}

const has_payment_method = computed((): boolean => customerStore.customer.stripe_payment_methods.length !== 0)

const has_default_payment_method = computed((): boolean =>
  customerStore.customer.stripe_payment_methods.some((payment_method) =>
    is_pm_default(payment_method, {
      billable: billable,
      default_payment_method_id: default_payment_method_id.value,
    }),
  ),
)

const can_pay = computed((): boolean => {
  return has_payment_method.value && has_default_payment_method.value
})

onMounted(async () => {
  // removes page flicker when returning to this page
  if (customerStore.customer !== null && productStore.products.length > 0) {
    assignDefaults()
  }

  // implement promise.all to allow for parallel requests
  // prettier-ignore
  await Promise.all([
    customerStore.retrieve(),
    productStore.list(),
  ])

  const stripe_subscriptions = customerStore.customer?.stripe_subscriptions?.filter(
    (subscription) =>
      subscription.metadata.billable_id === billable.id && subscription.metadata.billable_type === billable.type,
  )
  // loop billable subscriptions and re-sync
  await Promise.all(
    stripe_subscriptions.map(async (subscription) => {
      return await customerStore.sync_subscription(subscription.id as string)
    }),
  )

  assignDefaults()
})
</script>

<template>
  <TheLayout>
    <div class="h-full py-12 sm:mx-12" v-if="!ready">
      <div class="flex h-full items-center justify-center pb-32">
        <VLoading class="h-6 w-6" />
      </div>
    </div>
    <div v-if="ready">
      <!-- nav -->
      <VSection>
        <div class="flex items-center justify-between">
          <div>
            <RouterLink
              :to="{
                name: 'investing.entity-overview',
                params: {
                  slug: route.params.slug,
                  entity_type: route.params.billable_type,
                  entity_id: route.params.billable_id,
                },
              }"
            >
              <VButtonInvisible>← Back</VButtonInvisible>
            </RouterLink>
            <!-- <a :href="`${rails_url()}/funds/${billable.id}`">
              <VButtonInvisible>← Back</VButtonInvisible>
            </a> -->
          </div>
          <div v-if="authStore.is_site_admin">
            <ViewInStripeButton
              :livemode="customerStore.customer.livemode"
              :resource="{ customer: customerStore.customer.stripe_customer_id }"
            />
          </div>
        </div>
      </VSection>
      <!-- stats -->
      <template v-if="authStore.is_site_admin">
        <VSection v-if="customerStore.customer.analytics.length > 0">
          <VStat :stats="customerStore.customer.analytics"></VStat>
        </VSection>
      </template>
      <!-- payment method message -->
      <VSection class="pay-now-container relative" v-show="!can_pay">
        <div class="rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] p-4">
          <p class="text-sm font-medium text-gray-700">
            <template v-if="!has_payment_method">Please add a payment method below</template>
            <template v-else-if="!has_default_payment_method">Please select a default payment method below</template>
          </p>
        </div>
      </VSection>
      <!-- checkout -->
      <VSection class="pay-now-container relative" v-if="has_unpaid_subscriptions">
        <VStat
          :color="pay_now_classes"
          :stats="[
            {
              name: 'Due Today',
              previous_value: formatted_invoice_subtotal_amount,
              value: formatted_invoice_amount_remaining,
            },
          ]"
        ></VStat>
        <div class="absolute bottom-0 right-0 top-0 flex flex-col items-end justify-center pr-8">
          <VButton :disabled="!can_pay" size="xl" :click="pay" :loading="is_payment_loading" class="px-16"
            >Pay now</VButton
          >
        </div>
      </VSection>

      <!-- error block-->
      <VSection :class="payment_error_class">
        <div class="rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] p-4">
          <p class="text-sm font-medium text-gray-700">Payment failed. Error: {{ payment_error }}</p>
        </div>
      </VSection>
      <!-- current plan -->
      <VSection label="Current Plan">
        <div class="space-y-6">
          <!-- prettier-ignore -->
          <template v-for="item in items">
            <div v-if="(is_sub(item.id) && !is_sub_canceled(item.subscription)) || is_sub_sched(item.id)">
              <SubscriptionCard v-if="is_sub(item.id)" :actions="false" :subscription="item.subscription" />
              <SubscriptionScheduleCard v-if="is_sub_sched(item.id)" :subscription_schedule="item.subscription_schedule" />
            </div>
          </template>
        </div>
      </VSection>
      <!-- payment methods -->
      <VSection :label="`Payment Method${customerStore.customer.stripe_payment_methods.length > 1 ? 's' : ''}`">
        <div class="relative items-start justify-between lg:flex">
          <div>
            <PaymentMethodCard
              v-for="payment_method in customerStore.customer.stripe_payment_methods"
              :billable="billable"
              :default_payment_method_id="default_payment_method_id"
              :payment_method="payment_method"
              :setDefaultPaymentMethod="onSetDefaultPaymentMethod"
            />
          </div>
          <div>
            <RouterLink :to="{ name: 'billing-entity-add-payment-method' }">
              <VButton>Add payment method</VButton>
            </RouterLink>
          </div>
        </div>
      </VSection>
    </div>
  </TheLayout>
</template>
