<script setup lang="ts">
import { format as dateFormat } from 'date-fns'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import CallsBarChart from '@/modules/investing/components/calls-bar-chart.vue'
import DistributionsBarChart from '@/modules/investing/components/distributions-bar-chart.vue'
// import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import {
  VButtonGroup,
  VSection,
  VStats,
  VStatsHero,
  VButton,
  VDescriptionList,
  VSkeletonBar,
} from '@/modules/shared/components'
import { initialMoney } from '@/modules/shared/utils/money'
import { parse, format } from '@/modules/shared/utils/v-table'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useExtendedI18n } from '@/i18n'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

///////////////////////////////////////////////////////////////////////////////

const parseRate = (rate) => (rate ? (parse(rate, 'number') as number) : 0)

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const workspaceStore = useWorkspaceStore()
const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

///////////////////////////////////////////////////////////////////////////////
// Insights
///////////////////////////////////////////////////////////////////////////////

// collect and transform into camelCase
const entityInsights = computed(() => {
  const insights = entityStore.entity?.insights

  if (!insights) {
    return {
      initialValue: initialMoney,
      currentValue: initialMoney,
      commitment: initialMoney,
      committed: initialMoney,
      commitmentRemaining: initialMoney,
      cashPosition: initialMoney,
      hurdleRemaining: initialMoney,
      calledOutstandingTotal: initialMoney,
      calledOutstandingCapital: initialMoney,
      calledOutstandingManagementFee: initialMoney,
      calledOutstandingOtherFee: initialMoney,
      calledSettledTotal: initialMoney,
      calledSettledCapital: initialMoney,
      calledSettledManagementFee: initialMoney,
      calledSettledOtherFee: initialMoney,
      distributedCarriedInterest: initialMoney,
      distributedHurdleRemaining: initialMoney,
      distributedInterest: initialMoney,
      distributedNet: initialMoney,
      distributedOtherFee: initialMoney,
      distributedPreferredReturn: initialMoney,
      distributedProfit: initialMoney,
      distributedReturnOfCapital: initialMoney,
      dpi: 0,
      moic: 0,
      roi: 0,
      rvpi: 0,
      tvpi: 0,
      xirr: 0,
    }
  }

  return {
    initialValue: insights.initial_value,
    currentValue: insights.current_value,

    commitment: insights.commitment,
    committed: insights.committed,
    commitmentRemaining: insights.commitment_remaining,
    cashPosition: insights.cash_position,
    hurdleRemaining: insights.hurdle_remaining,

    calledOutstandingTotal: insights.called_outstanding_total,
    calledOutstandingCapital: insights.called_outstanding_capital,
    calledOutstandingManagementFee: insights.called_outstanding_management_fee,
    calledOutstandingOtherFee: insights.called_outstanding_other_fee,
    calledSettledTotal: insights.called_settled_total,
    calledSettledCapital: insights.called_settled_capital,
    calledSettledManagementFee: insights.called_settled_management_fee,
    calledSettledOtherFee: insights.called_settled_other_fee,

    distributedCarriedInterest: insights.distributed_carried_interest,
    distributedHurdleRemaining: insights.distributed_hurdle_remaining,
    distributedInterest: insights.distributed_interest,
    distributedNet: insights.distributed_net,
    distributedOtherFee: insights.distributed_other_fee,
    distributedPreferredReturn: insights.distributed_preferred_return,
    distributedProfit: insights.distributed_profit,
    distributedReturnOfCapital: insights.distributed_return_of_capital,

    dpi: insights.dpi,
    moic: insights.moic,
    roi: insights.roi,
    rvpi: insights.rvpi,
    tvpi: insights.tvpi,
    xirr: insights.xirr,
  }
})

const commitmentTotals = computed(() => {
  return {
    committed: entityInsights.value.committed,
    commitmentRemaining: entityInsights.value.commitmentRemaining,
    hurdleRemaining: entityInsights.value.hurdleRemaining,
  }
})

const callTotals = computed(() => {
  return {
    outstanding: {
      called: entityInsights.value.calledOutstandingTotal,
      capital: entityInsights.value.calledOutstandingCapital,
      managementFee: entityInsights.value.calledOutstandingManagementFee,
      otherFee: entityInsights.value.calledOutstandingOtherFee,
    },
    settled: {
      called: entityInsights.value.calledSettledTotal,
      capital: entityInsights.value.calledSettledCapital,
      managementFee: entityInsights.value.calledSettledManagementFee,
      otherFee: entityInsights.value.calledSettledOtherFee,
    },
  }
})

const distributionTotals = computed(() => {
  return {
    carriedInterest: entityInsights.value.distributedCarriedInterest,
    hurdleRemaining: entityInsights.value.distributedHurdleRemaining,
    interest: entityInsights.value.distributedInterest,
    net: entityInsights.value.distributedNet,
    otherFee: entityInsights.value.distributedOtherFee,
    preferredReturn: entityInsights.value.distributedPreferredReturn,
    profit: entityInsights.value.distributedProfit,
    returnOfCapital: entityInsights.value.distributedReturnOfCapital,
  }
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entity_id, slug } = useRoute().params as { entity_id: string; slug: string }

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const isAdmin = computed(
  () => authStore.is_site_or_group_admin || !!entityStore.getAdmin(authStore.current_user.investor_id),
)

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { n } = useExtendedI18n()
const { skeleton, hideSkeleton } = useSkeleton()

const waterfall_info = computed(() => {
  switch (entity.value?.waterfall_calculation) {
    case 'american':
      return 'American waterfall is calculated on a deal-by-deal basis, and a GP is compensated for each successful deal. This often allows the GP to begin taking a share of the profits, or carried interest, earlier in the life of a fund.'
    case 'european':
      return "In a European waterfall, 100% of the contributed capital and preferred return is paid out to investors on a pro rata basis before the GP receives any distribution of carried interest. Because it's pro rata, all capital is treated equally, and distributions are paid out in proportion to the amount of capital invested."
    default:
      return null
  }
})

onMounted(async () => {
  // if (entity.value) {
  //   hideSkeleton()
  // }
  await entityStore.fetchEntity(entity_id, { slug })
  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="overview" :disableSideSection="false">
    <VSection label="360° Portfolio overview">
      <VStatsHero
        class="mb-6"
        :skeleton="skeleton"
        :currentValue="entityInsights.currentValue"
        :initialValue="entityInsights.initialValue"
        :hiddenFields="[
          !workspaceStore.isDataAccessible('investments.initial_value', isAdmin) ? 'initial' : null,
          !workspaceStore.isDataAccessible('investments.current_value', isAdmin) ? 'current' : null,
        ]"
      />
      <VStats
        :skeleton="skeleton"
        :stats="[
          {
            colspan: 2,
            label: 'ROI',
            type: 'percent',
            value: entityInsights.roi,
            visible: workspaceStore.isDataAccessible('investments.roi', isAdmin),
          },
          {
            colspan: 2,
            label: 'MOIC',
            type: 'multiple',
            value: entityInsights.moic,
            visible: workspaceStore.isDataAccessible('investments.moic', isAdmin),
          },
          {
            colspan: 2,
            label: 'Cash position',
            type: 'currency',
            value: entityInsights.cashPosition,
            visible: workspaceStore.isDataAccessible('investments.cash_position', isAdmin),
          },
          {
            colspan: 2,
            label: 'Distributed',
            type: 'currency',
            value: entityInsights.distributedNet,
            visible: workspaceStore.isDataAccessible('investments.distributions', isAdmin),
          },
        ]"
      />
    </VSection>
    <VSection label="Entity overview">
      <VStats
        class="mb-6"
        :skeleton="skeleton"
        :stats="[
          {
            label: 'XIRR',
            info: `If you see a negative XIRR, but no change in investment value, this could be because of fees paid to execute the investment negatively impacting your return short-term.`,
            type: 'percent',
            value: entityInsights.xirr,
          },
          {
            label: 'TVPI',
            type: 'multiple',
            value: entityInsights.tvpi,
          },
          {
            label: 'RVPI',
            type: 'multiple',
            value: entityInsights.rvpi,
          },
          {
            label: 'DPI',
            type: 'multiple',
            value: entityInsights.dpi,
          },
        ]"
      />
      <CallsBarChart class="mb-6" :commitmentTotals="commitmentTotals" :callTotals="callTotals" :skeleton="skeleton" />
      <DistributionsBarChart :distributionTotals="distributionTotals" :skeleton="skeleton" />
    </VSection>

    <!-- Side Section -->
    <template #aside>
      <VSection label="General Information">
        <template #actions v-if="isAdmin">
          <a :href="`${rails_url()}/funds/${entity_id}/edit#general-information`">
            <VButton size="xs">Edit</VButton>
          </a>
        </template>
        <template #default>
          <VDescriptionList
            v-if="isAdmin && entity?.is_venture360_the_administrator"
            class="mb-1"
            :items="[
              {
                term: 'Billing',
                description: 'View',
              },
            ]"
            :skeleton="skeleton"
          >
            <template #description="{ item }">
              <RouterLink
                :to="{
                  name: 'billing-entity',
                  params: { billable_id: entity_id, billable_type: entity.entity_type },
                }"
                class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                v-if="entity"
              >
                {{ item.description }}
              </RouterLink>
            </template>
          </VDescriptionList>
          <VDescriptionList
            v-if="isAdmin && entity?.entity_type === 'fund'"
            class="mb-1"
            :items="[
              {
                term: 'Fund GP',
                description: entity.fund_gp_name,
              },
            ]"
            :skeleton="skeleton"
          />
          <VDescriptionList
            class="mb-4"
            :items="[
              {
                term: 'Managers',
                description: entity?.admins,
              },
            ]"
            :layout="entity?.admins?.length || 0 > 1 ? 'stacked' : 'adjacent'"
            :skeleton="skeleton"
          >
            <template #description="{ item }">
              <span v-for="(admin, index) in entity?.admins" :key="admin.id">
                <RouterLink
                  v-if="isAdmin"
                  class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                  :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
                >
                  {{ admin.name }}
                </RouterLink>
                <span v-else> {{ admin.name }} </span>
                <span v-if="index !== entity.admins.length - 1">, </span>
              </span>
            </template>
          </VDescriptionList>

          <VDescriptionList
            v-if="isAdmin"
            class="mb-4"
            :items="[
              {
                term: 'Type',
                description: entity?.type,
              },
              {
                term: 'Date Established',
                description: entity?.date_established ? dateFormat(new Date(entity.date_established), 'PP') : null,
              },
              {
                term: 'EIN',
                description: entity?.tax_id,
              },
              {
                term: 'Currency',
                description: entity?.currency,
              },
            ]"
            :skeleton="skeleton"
          />
          <VDescriptionList
            v-if="isAdmin"
            :items="[{ term: 'Address', description: entity?.address }]"
            layout="stacked"
          >
            <template #description="{ item }">
              <VSkeletonBar v-if="skeleton" />
              <div v-else>
                <div>{{ item.description?.line1 }}</div>
                <div v-if="!!item.description?.line2">{{ item.description?.line2 }}</div>
                <div>
                  {{ item.description?.city }} {{ item.description?.state }}
                  {{ item.description?.postal_code }}
                </div>
                <div>{{ item.description?.country }}</div>
              </div>
            </template>
          </VDescriptionList>
        </template>
      </VSection>
      <VSection label="Structure">
        <template #actions v-if="isAdmin">
          <a :href="`${rails_url()}/funds/${entity_id}/edit#structure`">
            <VButton size="xs">Edit</VButton>
          </a>
        </template>
        <template #default>
          <VDescriptionList
            :items="[
              {
                term: 'Management fee',
                description: n(parseRate(entity?.structure?.management_fee_rate), 'percent'),
              },
              {
                term: 'Carried interest',
                description: n(parseRate(entity?.structure?.carried_interest_rate), 'percent'),
              },
              {
                term: 'Preferred return',
                description: n(parseRate(entity?.structure?.preferred_return_rate), 'percent'),
              },
            ]"
            :skeleton="skeleton"
          />
          <VDescriptionList
            v-if="entity?.entity_type === 'fund'"
            class="mt-4"
            :items="[
              {
                term: 'Capital Call Frequency',
                description: entity?.capital_call_frequency || '--',
              },
              {
                term: 'Investment Period',
                description: entity?.investment_period ? `${entity?.investment_period} years` : '--',
              },
              {
                term: 'Interest Catch-up Provision',
                description: format(entity?.has_interest_catch_up_provision, 'boolean'),
              },
              {
                term: 'Waterfall',
                description: entity?.waterfall_calculation || '--',
                info: waterfall_info,
              },
            ]"
            :skeleton="skeleton"
          />
        </template>
      </VSection>
    </template>
  </EntityLayout>
</template>
