import EntitiesPage from '../pages/entities-page.vue'
import InsightsPage from '../pages/insights-page.vue'

export default [
  {
    component: EntitiesPage,
    name: 'admin.entities',
    path: '/:slug/admin/entities',
  },
  {
    component: InsightsPage,
    name: 'admin.insights',
    path: '/:slug/admin/insights',
  },
]
