import { get, max, min, uniq } from 'lodash'
import { to_currency } from './currency'
import { add, divide } from './math'
import { unflatten } from 'flat'
import { Money } from './money'
import { format as formatfns } from 'date-fns'

export interface Column {
  key: string
  name: string
  type:
    | 'actions'
    | 'boolean'
    | 'currency'
    | 'currency-object'
    | 'date'
    | 'integer'
    | 'number'
    | 'percent'
    | 'string'
    | 'template'
  aggregate?: 'average' | 'count' | 'max' | 'min' | 'mean' | 'median' | 'mode' | 'sum' | 'unique'
  align?: string
  is_visible: boolean
  fixed?: boolean
  sorted?: boolean
  csv_key?: string
  is_accessible?: boolean
  enable_thead_slot?: boolean
  sort_order?: 'asc' | 'desc'
}

export interface Currency {
  currency: string
  value: number
}

// prettier-ignore
const CURRENCY_MAP = {
  '$': 'USD',
  '€': 'EUR',
  '£': 'GBP',
  '¥': 'JPY',
}

function calculate_aggregate(items: any[], column: Column): Money | number | string {
  if (!column.aggregate || items.length === 0) return ''

  const values = items.map((item) => {
    if (['currency'].includes(column.type)) {
      return parse(unflattenKey(item, column.key).amount, 'number')
    }

    return parse(get(item, column.key), column.type)
  })

  let aggregate_value, common_aggregate_value

  switch (column.aggregate) {
    case 'average':
    case 'mean':
      aggregate_value = divide(
        (values as number[]).reduce((a: number, b: number) => add(a, b), 0),
        values.length,
      )
      break
    case 'count':
      aggregate_value = values.length
      break
    case 'max':
      aggregate_value = max(values)
      break
    case 'min':
      aggregate_value = min(values)
      break
    case 'median':
      const sorted_values = (values as number[]).sort((a: number, b: number) => a - b)
      const middle = Math.floor(sorted_values.length / 2)
      // prettier-ignore
      aggregate_value = sorted_values.length % 2 === 0 ? (sorted_values[middle] + sorted_values[middle - 1]) / 2 : sorted_values[middle]
      break
    case 'mode':
      const counts: { [key: string]: number } = values.reduce((a: any, b: any) => ({ ...a, [b]: (a[b] || 0) + 1 }), {})
      const max_count = Math.max(...Object.values(counts))
      const aggregate_values = Object.keys(counts).filter((key) => counts[key] === max_count)

      if (aggregate_values.length === 1) {
        aggregate_value = aggregate_values[0]
      } else {
        aggregate_value = null
      }

      break
    case 'sum':
      if (column.type === 'percent') {
        const percents = items.map((item) => parse(unflattenKey(item, column.key), 'number'))
        aggregate_value = percents.reduce((a: number, b: number) => add(a, b), 0)
      } else {
        const amounts = items.map((item) => parse(unflattenKey(item, column.key).amount, 'number'))
        const common_amounts = items.map((item) => parse(unflattenKey(item, column.key).common_amount, 'number'))

        aggregate_value = amounts.reduce((a: number, b: number) => add(a, b), 0)
        common_aggregate_value = common_amounts.reduce((a: number, b: number) => add(a, b), 0)
      }
      break
    case 'unique':
      aggregate_value = uniq(values).length
      break
    default:
      aggregate_value = ''
  }

  if (column.type === 'currency') {
    return {
      amount: aggregate_value,
      common_amount: common_aggregate_value,
      currency: unflattenKey(items[0], column.key).currency,
      common_currency: unflattenKey(items[0], column.key).common_currency,
      date: null,
    }
  }

  return aggregate_value
}

function format(value: any, type: string): string {
  if (value === null) return '--'
  if (value === undefined || value === '') return ''

  switch (type) {
    case 'boolean':
      return value === true ? 'Yes' : 'No'
    case 'currency':
      let currency = value
      if (typeof value === 'string') currency = parseToCurrency(value)
      if (typeof value === 'number') currency = parseToCurrency(`$${value}`)

      return to_currency(currency.value, currency.currency)
    case 'currency-object':
      return to_currency(value.value, value.currency)
    case 'date':
      let date = typeof value === 'number' ? new Date(value * 1000) : new Date(value)
      date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
      return date.toLocaleDateString()
    case 'time':
      let datetime = typeof value === 'number' ? new Date(value * 1000) : new Date(value)
      datetime = new Date(datetime.getTime() + datetime.getTimezoneOffset() * 60 * 1000)
      return formatfns(datetime, 'hh:mm aa')
    case 'multiple':
      let strValue = value.toFixed(2)

      if (strValue.endsWith('.00') || strValue.endsWith('0')) {
        strValue = strValue.slice(0, -1)
      }

      return `${strValue}x`
    case 'number':
      return Number(value).toLocaleString()
    case 'percent':
      return `${(value * 100).toFixed(2)}%`
    default:
      return value.toString()
  }
}

function parse(value: Currency | Date | number | string, type: string) {
  switch (type) {
    case 'currency':
      return parseFloat(value.toString().replace(/[^0-9.-]+/g, ''))
    case 'currency-object':
      return (value as Currency).value
    case 'number':
      return parseFloat(value?.toString())
    case 'percent':
      return parseFloat(value.toString().replace('%', '')) / 100
    default:
      return value?.toString().toLocaleLowerCase()
  }
}

function parseToCurrency(value: string): Currency {
  return {
    currency: CURRENCY_MAP[value[0]],
    value: parse(value, 'currency') as number,
  }
}

const unflattenKey = (obj: Object, startsWith: string) => {
  const filtered = Object.keys(obj).reduce((acc: { [key: string]: any }, key) => {
    if (key.startsWith(startsWith)) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
  const keys = startsWith?.split('.') || []
  return keys.reduce((obj, k) => obj && obj[k], unflatten(filtered))
}

export { calculate_aggregate, format, parse, unflattenKey }
