<script setup lang="ts">
import { capitalize, startCase } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { TheHeader, VButton, VDropdownImproved, VIcon } from '@/modules/shared/components'
import { createOptions } from '@/modules/shared/utils/form'
import { useEntityStore } from '../stores/entity-store'
import { useAuthStore } from '../../auth/stores/auth-store'
import { rails_url } from '@/modules/shared/utils/rails'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

function formattedEntityType(entity_type: string) {
  switch (entity_type) {
    case 'fund':
      return capitalize(t('shared.fund'))
    case 'gp':
      return `${startCase(t('shared.general partner'))} (${t('shared.GP')})`
    case 'spv':
      return `${startCase(t('shared.special purpose vehicle'))} (${t('shared.SPV')})`
    default:
      return null
  }
}

function backButtonLabel(entity_type: string) {
  switch (entity_type) {
    case 'fund':
      return `${capitalize(t('shared.fund', 0))}`
    case 'gp':
      return `${t('shared.GP', 0)}`
    case 'spv':
      return `${t('shared.SPV', 0)}`
    default:
      return null
  }
}

///////////////////////////////////////////////////////////////////////////////
const { t } = useI18n()
const route = useRoute()
const entity = {
  id: route.params.entity_id || route.params.entity_id || route.params.transacting_id,
  type: route.params.entity_type || route.params.entity_type || route.params.transacting_type,
}
const authStore = useAuthStore()
const entityStore = useEntityStore()
const investorStore = useInvestorStore()
const currentEntity = ref(null)

const investorOptions = computed(() => createOptions(investorStore.state.profiles, { label: 'name' }))
const viewingAs = computed(() => {
  if (investorStore.state.selectedKeys.length === Object.keys(investorStore.state.profiles).length) {
    return `all ${t('shared.investor', 0)}`
  }

  if (investorStore.state.selectedKeys.length === 1) {
    return investorStore.state.profiles[investorStore.state.selectedKeys[0]].name
  }

  if (investorStore.state.selectedKeys.length > 1 || investorStore.state.selectedKeys.length < 1) {
    return `${investorStore.state.selectedKeys.length} ${t('shared.investor', investorStore.state.selectedKeys.length)}`
  }
})

const back_url_config = computed(() => {
  if (authStore.is_company_user) {
    return {
      to: `${rails_url()}/${entity.type}s`,
      fallback_url: `${rails_url()}/${entity.type}s`,
      label: `← Back`,
    }
  } else if (!authStore.is_site_or_group_admin) {
    return {
      to: {
        name: 'dashboard',
      },
      fallback_url: {
        name: 'investing.entity-overview',
        params: { slug: route.params.slug, entity_type: entity.type, entity_id: entity.id },
      },
      label: `← Back`,
    }
  } else {
    return {
      to: {
        name: 'investing.entities',
        params: {
          entity_type: `${entity.type}s`,
          slug: route.params.slug,
        },
      },
      fallback_url: {
        name: 'investing.entity-overview',
        params: { slug: route.params.slug, entity_type: entity.type, entity_id: entity.id },
      },
      label: `← Back to all ${backButtonLabel(entity.type as string)}`,
    }
  }
})

onMounted(async () => {
  await entityStore.fetchEntityInfo({ type: entity.type as string, id: entity.id as string })
  currentEntity.value = entityStore.items.get(`${entity.type}:${entity.id}`)
})
</script>

<template>
  <TheHeader
    :descriptor="formattedEntityType(currentEntity?.entity_type)"
    :subtitle="currentEntity?.legal_name"
    :title="currentEntity?.name"
    :back_url_config="back_url_config"
  >
    <template #actions>
      <div class="flex items-center justify-end space-x-2">
        <VDropdownImproved
          v-if="!['investing.investors', 'investing.entity.portfolio'].includes(route.name)"
          v-model="investorStore.state.selectedKeys"
          aligned="right"
          :options="investorOptions"
          class="z-50"
        >
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>Filter by {{ viewingAs }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>

        <slot name="actions"></slot>
      </div>
    </template>
  </TheHeader>
</template>
