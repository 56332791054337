import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/core/composables/useFetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////


// TODO: move to shared types
// type Insight = {
//   _cid: CID // currently, this doesn't exist on the server
//   _key: string // used to create _cid until the server provides a composite id
//   id: string
//   cash_position_globally: number
//   deal_volume_by_group: number
//   total_fund_commitment_globally: number
//   total_spv_commitment_globally: number
//   initial_value_of_investments_globally: number
//   current_value_of_investments_globally: number
//   number_of_flexnote_spvs_globally: number,
//   number_of_traditional_spvs_globally: number,
//   number_of_funds_globally: number,
//   roi_by_group: number,
//   roi_by_investor_set: number,
//   roi_globally: number,
//   moic_by_group: number,
//   moic_by_investor_set: number,
//   moic_globally: number,
//   xirr_by_group: number,
//   xirr_by_investor_set: number,
//   xirr_globally: number,
//   tvpi_by_group: number,
//   tvpi_by_investor_set: number,
//   tvpi_globally: number,
//   rvpi_by_group: number,
//   rvpi_by_investor_set: number,
//   rvpi_globally: number,
//   dpi_by_group: number,
//   dpi_by_investor_set: number,
//   dpi_globally: number,
//   total_commitment_by_investor_set: number,
//   average_investors_by_investor_set: number,
//   average_cash_position_by_group: number,
//   average_cash_position_by_investor_set: number
// }

type Value = {
  currency: string
  value: number
} | number

type Insight = {
  _cid: CID // currently, this doesn't exist on the server
  _key: string // used to create _cid until the server provides a composite id
  id: string
  name: string
  value: Value
  type: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type InsightsMap = Map<CID, Insight>

export const useInsightsStore = defineStore('investing/insightsStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/v4/${route.params.slug}/insights`)
  const notificationStore = useNotificationStore()

  const items = ref<InsightsMap>(new Map())
  const allInsights = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))
  const fetchInsights = async (investorSetId: string, insights: string) => {
    clearItems(items)

  const url = investorSetId !== undefined ? `${baseUrl.value}/investor-sets/${investorSetId}?stats=${insights}` : `${baseUrl.value}?stats=${insights}`
    const data = await useFetch(url)
      .get<{ data: Insight[] }>()

    // if (error.value) {
    //   // TODO: handle error (e.g., display a message to the user)
    //   console.error(error.value)
    //   notificationStore.enqueue('error', 'Insights failed to load')
    //   return
    // }
    addItems(
      items,
      data.map((item) => ({ ...item, _key: item.name })),
    )
  }

  return {
    allInsights,
    fetchInsights,
  }
})
