<script setup lang="ts">
import { onClickOutside, useConfirmDialog, useMagicKeys } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { VIcon } from './'

// NOTE: this is duplicated in multiple places
// TODO: move to shared types
type Option = {
  disabled?: boolean
  label: string
  text?: string
  value: string
}

const props = withDefaults(
  defineProps<{
    aligned?: string
    hideDisabled?: boolean
    modelValue: string[]
    multi?: boolean
    showSelectedList?: boolean
    options: Option[]
    onChange?: any
    onClose?: any
  }>(),
  {
    aligned: 'left',
    hideDisabled: false,
    multi: true,
    showSelectedList: false,
    onChange: () => {},
    onClose: () => {},
  },
)

const emit = defineEmits(['update:modelValue'])

const { isRevealed, reveal: revealDialog, cancel: cancelDialog } = useConfirmDialog()

function isSelected(value: string) {
  return props.modelValue.includes(value)
}

const allSelected = computed(() => props.options?.length === props.modelValue?.length)

const toggleSelect = (value: string) => {
  let newValue

  if (props.multi) {
    newValue = isSelected(value) ? props.modelValue.filter((val) => val !== value) : [...props.modelValue, value]
  } else {
    newValue = [value]
  }

  emit('update:modelValue', newValue)
  if (props.onChange) props.onChange(newValue)
}

const optionValues = computed(() => props.options.map((option) => option.value))

const toggleAll = () => {
  emit('update:modelValue', allSelected.value ? [] : optionValues.value)
  if (props.onChange) props.onChange(allSelected.value ? [] : optionValues.value)
}

const valuesBefore = ref<string[]>([])
const valuesAfter = ref<string[]>([])

const reveal = () => {
  valuesBefore.value = [...props.modelValue]
  revealDialog()
}

const cancel = () => {
  valuesAfter.value = [...props.modelValue]
  if (props.onClose && isRevealed.value) props.onClose(valuesBefore.value, valuesAfter.value)
  cancelDialog()
}

const target = ref(null)

onClickOutside(target, (event) => cancel())

const { escape } = useMagicKeys()

watch(escape, (v) => {
  if (v) {
    cancel()
  }
})
</script>

<template>
  <div class="relative z-50" :class="aligned === 'left' ? 'text-left' : 'text-right'" ref="target">
    <span @click="isRevealed ? cancel() : reveal()">
      <slot name="default"></slot>
    </span>
    <ul
      v-show="isRevealed"
      class="absolute z-50 mt-1 max-h-[480px] min-w-[220px] divide-y-0 divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-[1px] ring-gray-700/20"
      :class="aligned === 'left' ? 'left-0' : 'right-0'"
    >
      <li
        v-if="multi"
        @click="toggleAll"
        class="flex cursor-pointer items-center space-x-2 border-b px-3 py-1.5 pr-6 text-sm text-gray-700 hover:bg-gray-100"
      >
        <div class="flex h-4 w-4 items-center justify-center rounded border bg-white">
          <VIcon name="check" class="h-3 w-3" :class="{ invisible: !allSelected }" />
        </div>

        <div class="font-semibold">{{ allSelected ? 'Deselect' : 'Select' }} all</div>
      </li>
      <li
        v-for="option in options"
        v-show="!(option.disabled && hideDisabled)"
        :key="option.label || option.text"
        class="relative flex cursor-pointer items-center space-x-2 whitespace-nowrap px-3 py-1.5 pr-6 text-sm text-gray-700 hover:bg-gray-100"
        @click="option.disabled ? '' : toggleSelect(option.value)"
      >
        <span class="text-gray-500" :class="{ invisible: !isSelected(option.value) }">
          <VIcon name="check" />
        </span>
        <div class="font-medium" :class="[!isSelected(option.value) && multi ? 'text-gray-500 line-through' : '']">
          {{ option.label || option.text }}
        </div>
      </li>
    </ul>
    <ul v-if="multi && showSelectedList" class="mt-3 flex flex-wrap items-start gap-2">
      <li
        v-for="option in options"
        :key="option.label || option.text"
        v-show="isSelected(option.value)"
        class="rounded-full bg-gray-100 px-2.5 py-0.5 text-xs text-gray-500"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>
