<script setup lang="ts">
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VBarChart } from '@/modules/shared/components'
import { divide } from '@/modules/shared/utils/math'
import { Money, toNumber, sum } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Global
///////////////////////////////////////////////////////////////////////////////

const { n } = useExtendedI18n()

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const ratio = (numerator: Money, denominator: Money) => {
  if (toNumberOrZero(denominator) === 0.0) return 0.0
  return divide(toNumberOrZero(numerator), toNumberOrZero(denominator))
}

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  distributionTotals: {
    carriedInterest: Money
    hurdleRemaining: Money
    interest: Money
    net: Money
    otherFee: Money
    preferredReturn: Money
    profit: Money
    returnOfCapital: Money
  }
  skeleton?: boolean
}>()

const denominator = computed<Money>(() => {
  const sorted_values = [
    props.distributionTotals.carriedInterest,
    props.distributionTotals.net,
    props.distributionTotals.hurdleRemaining,
    props.distributionTotals.otherFee,
    props.distributionTotals.preferredReturn,
    props.distributionTotals.profit,
    props.distributionTotals.returnOfCapital,
  ].sort((a, b) => toNumberOrZero(b) - toNumberOrZero(a))

  return sorted_values[0]
})

const toNumberOrZero = (money: Money) => {
  if (!money) return 0.0
  return toNumber(money)
}

const distributed_total_fees = computed(() =>
  sum([props.distributionTotals.carriedInterest, props.distributionTotals.otherFee]),
)
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white px-0 py-8 dark:border-[#161618] dark:bg-[#1B1B1F]">
    <VBarChart
      :segments="[
        {
          label: 'Net distributed',
          value: n(distributionTotals.net, 'currency'),
          style: 'bg-[#36326B]',
          ratio: ratio(distributionTotals.net, denominator),
          segments: [
            {
              label: 'Profit',
              ratio: ratio(distributionTotals.profit, distributionTotals.net),
              style: 'bg-[#36326B]/75',
              value: n(distributionTotals.profit, 'currency'),
            },
            {
              label: 'Preferred return',
              ratio: ratio(distributionTotals.preferredReturn, distributionTotals.net),
              style: 'bg-[#36326B]/75',
              value: n(distributionTotals.preferredReturn, 'currency'),
            },
            {
              label: 'Interest',
              ratio: ratio(distributionTotals.interest, distributionTotals.net),
              style: 'bg-[#36326B]/75',
              value: n(distributionTotals.interest, 'currency'),
            },
            {
              label: 'Return of capital',
              ratio: ratio(distributionTotals.returnOfCapital, distributionTotals.net),
              style: 'bg-[#36326B]/75',
              value: n(distributionTotals.returnOfCapital, 'currency'),
            },
            {
              label: 'Hurdle remaining',
              ratio: ratio(distributionTotals.hurdleRemaining, distributionTotals.net),
              style: 'bg-gray-300',
              value: n(distributionTotals.hurdleRemaining, 'currency'),
            },
          ],
        },
        {
          label: 'Fees',
          ratio: ratio(distributed_total_fees, denominator),
          style: 'bg-[#36326B]',
          value: n(distributed_total_fees, 'currency'),
          segments: [
            {
              label: 'Carried interest',
              ratio: ratio(distributionTotals.carriedInterest, distributionTotals.net),
              style: 'bg-[#8E6080]',
              value: n(distributionTotals.carriedInterest, 'currency'),
            },
            {
              label: 'Other fees',
              ratio: ratio(distributionTotals.otherFee, distributionTotals.net),
              style: 'bg-[#8E6080]/75',
              value: n(distributionTotals.otherFee, 'currency'),
            },
          ],
        },
      ]"
      :skeleton="skeleton"
      :expanded="true"
    />
  </div>
</template>
