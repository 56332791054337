<script setup lang="ts">
import { format } from 'date-fns'
import { computed, ref, onMounted } from 'vue'
import { VTextField, VButton, VLoadingSection, VSection } from '@/modules/shared/components'
import { CommitmentExpanded } from '../../../stores/better-entity-store'
import { toNumber } from '@/modules/shared/utils/money'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useInvestingInvestorStore } from '../../../stores/investor-store'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import VSelect from '../../v-select.vue'
import { useExtendedI18n } from '@/i18n'
import { initialMoney } from '@/modules/shared/utils/money'

const props = defineProps<{
  commitment: CommitmentExpanded
  disabledInvestorCIDs?: string[]
  modalUtil: any
  onSubmit: any
}>()

const { n } = useExtendedI18n()
const investorStore = useInvestingInvestorStore()
const skeleton = ref(true)
const loading = ref(false)

const assignCommitmentDataToForm = () => {
  if (!props.commitment?.id) return { ...initialState }

  return {
    commitment_id: props.commitment.id,
    from_investor_id: props.commitment.shareholder.cid,
    to_investor_id: null,
  }
}
const resetForm = () => {
  v$.value.$reset()
  form.value = { ...initialState }
}
const initialState = {
  commitment_id: null,
  from_investor_id: null,
  to_investor_id: null,
}
const investorOptions = computed(() => {
  const disabledInvestors = props.disabledInvestorCIDs || []

  return markDisabledOptions(createOptions(investorStore.investorItems, { label: 'name' }), disabledInvestors)
})

const form = ref(assignCommitmentDataToForm())
const rules = {
  commitment_id: { required },
  from_investor_id: { required },
  to_investor_id: { required },
}
const v$ = useVuelidate(rules, form, { $lazy: true })

const submitForm = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true

  await props.onSubmit(form.value)

  props.modalUtil.close()
  resetForm()
  loading.value = false
}

onMounted(async () => {
  await investorStore.fetchInvestors()
  skeleton.value = false
})
</script>

<template>
  <VSection label="Transfer Commitment">
    <div class="flex h-full flex-col gap-6">
      <VLoadingSection
        v-if="skeleton"
        class="mt-40"
        title="Preparing Your Form..."
        description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs smoothly."
      />
      <template v-else>
        <div class="flex-grow">
          <form @submit.prevent="submitForm">
            <div class="space-y-3">
              <VSelect
                v-model="form.from_investor_id"
                label="From"
                :options="investorOptions"
                property="from_investor_id"
                :v$="v$"
                :disabled="true"
              />
              <VSelect
                v-model="form.to_investor_id"
                label="To"
                :options="investorOptions"
                property="to_investor_id"
                :v$="v$"
              />
            </div>
          </form>
        </div>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="modalUtil.close" size="lg">Close</VButton>
          <VButton :click="submitForm" class="w-full" :loading="loading" size="lg" variant="primary">
            Reassign
          </VButton>
        </div>
      </template>
    </div>
  </VSection>
</template>
