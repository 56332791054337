import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Allocation = {
  _cid: CID
  _key: string
  id: string
  asset: object
  related_to: string
  term_sheet: { name: string; investment_type: string }
  type: string
  share_price: Money
  shares_sold: Money
  investors: Array<{ _cid: CID; total_received: Money; total_principal: Money; total_interest: Money }>
  date: string
  currency: string
  has_investor_set_disbursement: boolean
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type PortfolioDistributionMap = Map<CID, Allocation>

export const usePortfolioDistributionStore = defineStore('investing/portfolioDistributionStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<PortfolioDistributionMap>(new Map())
  const portfolio_distributions = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchPortfolioDistributions = async (entity_type, entity_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${entity_type}/${entity_id}/portfolio_distributions`)
      .get()
      .json<{ data: Allocation[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((portfolio_distribution) => ({ ...portfolio_distribution, _cid: portfolio_distribution.id })),
    )
  }

  return {
    items,
    portfolio_distributions,

    fetchPortfolioDistributions,
  }
})
