<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import useVuelidate from '@vuelidate/core'
import { required, sameAs, requiredIf } from '@vuelidate/validators'
import { computed, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { capitalize, startCase as _startCase } from 'lodash'
import {
  VButton,
  VDropdownImproved,
  VIcon,
  VLink,
  VRadio,
  VSection,
  VTextField,
  VTextArea,
  VDropdownMultiple,
} from '@/modules/shared/components'
import VSelect from './v-select.vue'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { allCountries } from 'country-region-data'
import DisbursementPreferencesForm from './disbursement-preferences-form.vue'

function startCase(str: string) {
  return str
    .split(' / ')
    .map((part) => _startCase(part))
    .join(' / ')
}

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps<{
  other_entity?: any
}>()

const otherEntityStore = useOtherEntityStore()
const investorStore = useInvestingInvestorStore()

const countryOptions = computed(() =>
  allCountries.map((country) => {
    // format of allCountries is [countryName, countryCode, countryStates][]
    return {
      label: country[0],
      value: country[1],
    }
  }),
)

const stateOptions = computed(() => {
  if (!otherEntity.value.country) return []
  const country = allCountries.find((country) => country[1] === otherEntity.value.country)
  if (!country) return []

  return country[2].map((state) => {
    // format of state is [name, code][]
    return {
      label: state[0],
      value: state[1],
    }
  })
})

const investorOptions = computed(() => {
  return investorStore.individuals
    .map((investor) => ({
      label: investor.name,
      value: investor.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
})

const investorSetTypesOptions = computed(() => {
  const types = [
    'LLC',
    'LP',
    'LLP',
    'Ltd',
    'Non-Profit Corporation',
    'C-Corp',
    'S-Corp',
    'Pte Ltd',
    'Pty Ltd',
    'Trust',
    'IRA',
    'ROTH IRA',
    'Disregarded Entity',
    'Other',
  ]
  return types.map((type) => ({
    label: type,
    value: type,
  }))
})

const useManagementFee = ref(true)
const useCarriedInterest = ref(true)

const initialState = {
  id: null,
  name: '',
  date: null,
  type: '',
  tax_id: '',
  input_capital: false,
  fincen_id: '',
  management_fee_percentage: null,
  carried_interest: null,
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
  disbursement_method: 'wire',
  disbursement_wire_bank_name: '',
  disbursement_wire_bank_address: '',
  disbursement_wire_bank_routing_number: '',
  disbursement_wire_bank_account_number: '',
  disbursement_wire_repeat_bank_account_number: '',
  disbursement_wire_bank_swift_code: '',
  disbursement_wire_account_name: '',
  disbursement_wire_for_further_credit_to: '',
  disbursement_check_receiver_name: '',
  disbursement_other_details: '',
  partner_name: '',
  partner_tax_id: '',
  investor_admin_ids: [],
}

const original_bank_account_number = ref('')
const check_bank_account_number = computed(
  () => original_bank_account_number.value !== otherEntity.value.disbursement_wire_bank_account_number,
)

const rules = {
  name: { required },
  disbursement_wire_repeat_bank_account_number: {
    sameAsRepeat: sameAs(
      computed(() =>
        check_bank_account_number.value
          ? otherEntity.value.disbursement_wire_bank_account_number
          : otherEntity.value.disbursement_wire_repeat_bank_account_number,
      ),
    ),
  },
}

const setOtherEntity = () => {
  if (props.other_entity) {
    original_bank_account_number.value = props.other_entity.disbursement_wire_bank_account_number
    return {
      ...Object.keys(initialState).reduce((data, key) => {
        if (key === 'investor_admin_ids') return data
        data[key] = props.other_entity[key]
        return data
      }, {}),
      investor_admin_ids: props.other_entity.admins.map((admin) => admin.id),
    }
  } else {
    return { ...initialState }
  }
}

const otherEntity = ref(setOtherEntity())
const v$ = useVuelidate(rules, otherEntity, { $lazy: true })

const saveOtherEntity = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return
  const payload = {
    funding_entity: {
      ...Object.keys(otherEntity.value).reduce((data, key) => {
        if (['investor_admin_ids', 'disbursement_wire_repeat_bank_account_number'].includes(key)) return data
        data[key] = otherEntity.value[key]
        return data
      }, {}),
    },
    investor_admin_ids: otherEntity.value.investor_admin_ids,
  }

  if (props.other_entity) {
    await otherEntityStore.updateOtherEntity(payload)
    router.push({ name: 'investing.other-entity.overview' })
  } else {
    const data = await otherEntityStore.addOtherEntity(payload)
    if (data) router.push({ name: 'investing.other-entity.investors', params: { other_entity_id: data.id } })
  }
}

const removeOtherEntity = async () => {
  if (!window.confirm('Are you sure?')) return
  await otherEntityStore.removeOtherEntity(props.other_entity.id)
  router.push({ name: 'investing.other-entities' })
}

onMounted(async () => {
  const element = document.getElementById(route.hash.replace('#', ''))
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  await Promise.all([investorStore.fetchIndividuals('all')])
})
</script>

<template>
  <VSection class="flex gap-3" id="general-information">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.general information')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">1/5</span>
      </h3>
    </div>
    <div class="w-1/2">
      <VTextField
        v-model="otherEntity.name"
        :label="capitalize(t('shared.name'))"
        property="name"
        :v$="v$"
        class="mb-5"
      />
      <div class="mb-5 flex gap-5">
        <VTextField
          v-model="otherEntity.date"
          :label="capitalize(t('shared.date established'))"
          property="date"
          type="date"
          :v$="v$"
          class="w-1/2"
        />
        <div class="w-1/2">
          <VSelect
            v-model="otherEntity.type"
            :disabled="false"
            :label="capitalize(t('shared.type'))"
            :options="investorSetTypesOptions"
            property="type"
            :v$="v$"
          />
        </div>
      </div>
      <template v-if="otherEntity.type === 'Disregarded Entity'">
        <VTextField
          v-model="otherEntity.partner_name"
          :label="`Partner name`"
          property="partner_name"
          :v$="v$"
          class="mb-5"
        />
        <VTextField
          v-model="otherEntity.partner_tax_id"
          :label="`Partner Tax ID`"
          property="partner_tax_id"
          :v$="v$"
          class="mb-5"
        />
      </template>
      <div class="mb-5">
        <VTextField v-model="otherEntity.tax_id" :label="`Tax ID`" property="tax_id" :v$="v$" />
        <VLink
          to="https://sa.www4.irs.gov/modiein/individual/legal-structure.jsp"
          class="mt-1 cursor-pointer text-sm font-medium text-[#51889B]"
          target="_blank"
        >
          Get Tax ID
        </VLink>
      </div>
      <div class="mb-5">
        <VTextField
          v-model="otherEntity.fincen_id"
          :label="t('shared.FinCEN ID')"
          property="fincen_id"
          type="text"
          :v$="v$"
        />
        <div class="mt-1 flex items-center gap-1 text-sm text-gray-500">
          <a
            href="https://fincenid.fincen.gov/landing"
            class="cursor-pointer font-medium text-[#51889B] underline decoration-[#51889B]/50"
            target="_blank"
          >
            Generate {{ t('shared.FinCEN ID') }} here
          </a>

          <div class="group relative hover:block">
            <div
              class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:shadow-md hover:ring-gray-700/30 active:shadow-sm"
            >
              <VIcon name="help" class="h-5 w-5 text-gray-700" />
            </div>
            <div class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block">
              <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
                A FinCEN ID is a unique identifying number issued to an individual by FinCEN. Your FinCEN ID will be
                used for Beneficial Ownership Information Reporting and eliminates the need for us to require submission
                of a federal form of identification.
              </div>
            </div>
          </div>
        </div>
      </div>
      <VRadio
        v-model="otherEntity.input_capital"
        label="How do you like to allocate ownership in this entity?"
        :options="[
          { label: 'Capital', value: true },
          { label: 'Percent', value: false },
        ]"
        :v$="v$"
        optionsDirection="vertical"
        info="Depending on the entity type, you can allocate ownership in that entity based on either the capital contributed or by ownership percentage. Allocating ownership won't apply in all cases."
      />
    </div>
  </VSection>
  <VSection class="flex gap-3" id="administration">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.administration')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">2/5</span>
      </h3>
    </div>
    <div class="w-1/2">
      <div class="mb-5">
        <label for="" class="mb-1 block text-sm font-medium text-gray-700">
          {{ capitalize(t('shared.admin', 0)) }}
        </label>
        <VDropdownMultiple
          aligned="left"
          class="z-auto"
          :options="investorOptions"
          property="investor_admin_ids"
          v-model="otherEntity.investor_admin_ids"
          :show-selected-list="true"
        >
          <VButton size="xl" class="w-full">
            <div class="flex items-center justify-between">
              <div>Select multiple...</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownMultiple>
      </div>
    </div>
  </VSection>
  <VSection class="flex gap-3" id="structure">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.structure')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">3/5</span>
      </h3>
    </div>
    <div class="w-1/2">
      <VRadio
        v-model="useManagementFee"
        label="Management Fees"
        description="Do you track fees and display them to investors?"
        :options="[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]"
        default-option="Yes"
        options-direction="vertical"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="otherEntity.management_fee_percentage"
        v-if="useManagementFee"
        label="Annual Management Fee (%)"
        property="management_fee_percentage"
        description="Additional fees can be applied during capital calls."
        :v$="v$"
        class="mb-5"
      />
      <VRadio
        v-model="useCarriedInterest"
        label="Carried Interest"
        description="Do you participate in carried interest?"
        :options="[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]"
        default-option="Yes"
        options-direction="vertical"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="otherEntity.carried_interest"
        v-if="useCarriedInterest"
        label="Carried Interest (%)"
        property="carried_interest"
        :v$="v$"
        class="mb-5"
      />
    </div>
  </VSection>
  <VSection class="flex gap-3">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.address')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">4/5</span>
      </h3>
    </div>
    <div class="w-1/2">
      <VSelect
        v-model="otherEntity.country"
        :disabled="false"
        :label="capitalize(t('shared.country'))"
        :options="countryOptions"
        property="country"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="otherEntity.street1"
        :label="capitalize(t('shared.street'))"
        property="street1"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="otherEntity.street2"
        :label="`Street (continued)`"
        property="street2"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="otherEntity.city"
        :label="capitalize(t('shared.city'))"
        property="city"
        :v$="v$"
        class="mb-5"
      />
      <VSelect
        v-model="otherEntity.state"
        :disabled="false"
        :label="startCase(t('shared.state / province'))"
        :options="stateOptions"
        property="state"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="otherEntity.zip"
        :label="startCase(t('shared.zip / postal code'))"
        property="zip"
        :v$="v$"
        class="mb-5"
      />
    </div>
  </VSection>
  <VSection class="flex gap-3" id="disbursement-preference">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.disbursement preference')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">5/5</span>
      </h3>
    </div>
    <div class="w-1/2">
      <DisbursementPreferencesForm
        v-model="otherEntity"
        :v$="v$"
        :check_bank_account_number="!props.other_entity ? true : check_bank_account_number"
      />
      <div class="mt-24">
        <VButton variant="v-blue" size="lg" :click="saveOtherEntity">Confirm Changes</VButton>
      </div>
    </div>
  </VSection>
  <div class="my-16 h-1 bg-gray-50"></div>
  <VSection class="flex gap-3" v-if="props.other_entity">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">Danger Zone</h3>
      <p class="mt-6">Warning, actions in this section are irreversible. Please proceed with caution.</p>
    </div>
    <div class="w-1/2">
      <VButton variant="secondary" size="lg" :click="removeOtherEntity"> Remove </VButton>
    </div>
  </VSection>
</template>
